<template>
  <div class="login center h-v-100">
    <HeaderAuth />

    <section v-if="!registrando" class="container form">
      <h1>
        Create una cuenta <span> {{ send.registro }} </span>
      </h1>
      <form v-on:submit.prevent="register()">
        <section class="two">
          <label for="name">
            <section class="relative_inputs helvicaFont">
              <input
                type="text"
                id="name"
                v-model="value.name"
                placeholder="Nombre"
                autofocus
              />
            </section>
            <div class="error" />
          </label>
          <label for="last_name">
            <section class="relative_inputs helvicaFont">
              <input
                type="text"
                id="last_name"
                v-model="value.last_name"
                placeholder="Apellidos"
              />
            </section>
            <div class="error" />
          </label>
        </section>

        <section class="individual">
          <label for="email">
            <section class="relative_inputs helvicaFont">
              <input
                type="email"
                id="email"
                v-model="value.email"
                autocomplete="username"
                placeholder="Correo electrónico"
              />
            </section>
            <div class="error email_error"></div>
          </label>
        </section>

        <section class="two">
          <label for="password" class="relative_inputs helvicaFont">
            <div class="relative_inputs helvicaFont">
              <input
                type="password"
                v-model="value.password"
                id="password"
                autocomplete="new-password"
                placeholder="Contraseña"
              />
            </div>
            <div class="error" />
          </label>
          <label for="repeat" class="relative_inputs helvicaFont">
            <div class="relative_inputs helvicaFont">
              <input
                type="password"
                name="repeat"
                v-model="value.repeat"
                id="repeat"
                autocomplete="new-password"
                placeholder="Confirmar Contraseña"
              />
              <button
                type="submit"
                :disabled="botton.disabled"
                class="send_move"
              >
                <svg v-if="!loading" width="22" viewBox="0 0 45 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.9 40.17L43.44 20.59L24.9 1" stroke="white" stroke-miterlimit="10"/>
                    <path d="M43.45 20.59H0" stroke="white" stroke-miterlimit="10"/>
                </svg>
                <div class="loadingItem" v-else></div>
              </button>
            </div>
            <div class="error" />
          </label>
        </section>
      </form>
    </section>

    <section v-if="registrando" class="container form">
      <Vue3Lottie
        :pauseAnimation="true"
        :animationData="DogJSON"
        :height="500"
        :width="500"
      />
    </section>

    <section class="registrarse helvicaFont">
      <ButtonLoginView/>
      -
      <RouterLink :to="{ name: 'login' }"> Iniciar Sesión </RouterLink>
    </section>
  </div>
</template>

<script>
import validation from "@/helpers/validation";
import {
  register
} from "@/helpers/fetchs/user";
import HeaderAuth from "@/components/public/HeaderAuth.vue";

// Azure auth
import AzureService from "@/helpers/Azureservice";
import { PublicClientApplication } from "@azure/msal-browser";

// Lottie animation
import { Vue3Lottie } from "vue3-lottie";
import "vue3-lottie/dist/style.css";
import DogJSON from "@/assets/animation/send_message.json";

import ButtonLoginView from '@/components/loginMicrosoft/ButtonLoginView';

import { useToast } from "vue-toastification";

const toast = useToast();

// Auth with Firebase

export default {
  components: {
    HeaderAuth,
    Vue3Lottie,
    ButtonLoginView
  },
  data() {
    return {
      DogJSON,
      value: {
        name: "",
        last_name: "",
        email: "",
        password: "",
        repeat: "",
      },
      validation_all: {
        inputs: 0,
        email: "",
      },
      botton: {
        registro: "",
        disabled: false,
      },
      send: {
        registro: "",
      },
      account: "",
      registrando: false,
      loading: false,
    };
  },
  async created() {
    const azureServices = new AzureService();
    this.$msalInstance = new PublicClientApplication(
      azureServices.getMsalConfig().value
    );
  },
  methods: {

    // Validation all

    validation() {
      this.validation_all.inputs = 0;
      const elements_child = document.querySelectorAll("input");
      const type = "individual";
      elements_child.forEach((e) => {
        if (!validation(e, type)) {
          this.validation_all.inputs += 1;
        }
      });
    },

    // Send Register

    focus_button() {
      this.botton.registro = "Registrarse";
    },

    blur_button() {
      this.botton.registro = "";
    },

    async register() {

      this.validation();
      const lengt_inputs = document.querySelectorAll("input").length;

      if (lengt_inputs == this.validation_all.inputs) {
        const elements_child = document.querySelectorAll("input");
        elements_child.forEach((e) => {
          e.disabled = true;
        });

        this.registrando = true;
        this.botton.disabled = true;
        this.loading = true

        const form = new FormData();
        form.append("name", this.value.name);
        form.append("last_name", this.value.last_name);
        form.append("email", this.value.email);
        form.append("password", this.value.password);

        await register(form)
          .then((res) => {

            this.loading = false;

            if (res.status == 201) {
              return res.json().then((data) => {
                localStorage.setItem("email_verify", data.data.email);
                localStorage.setItem("verify", "send email");
                this.$store.commit("email_user", "al");
                this.$router.push({ name: "verify" });
              });
            } else if (res.status == 422) {
              this.registrando = false;

              return res.json().then((data) => {
                const email = document.querySelector(".email_error");
                if (data.error.error.email) {
                  elements_child.forEach((e) => {
                    e.disabled = false;
                  });
                  this.botton.registro = "Registrar";
                  this.send.registro = "";
                  this.botton.disabled = false;
                  email.textContent =
                    "Este correo electrónico ya ha sido registrado.";
                  email.classList.add("active");
                }
              });
            } else {
              this.registrando = false;

              this.send.registro = "";
              this.botton.disabled = false;
              toast.error(
                "Opps, estamos presentando problemas inténtelo de nuevo dentro de unos minutos.",
                {
                  position: "bottom-right",
                  timeout: 5000,
                  pauseOnHover: true,
                  hideProgressBar: true,
                  icon: false,
                  closeButton: false,
                }
              );
            }
          });
      }
    },
  },
};
</script>
