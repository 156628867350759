<template>
    <div class="login verify-active center h-v-100">
        <HeaderAuth v-if="!account_active" />
        <section v-if="account_active"></section>

        <section v-if="!account_active" class="container verify-active helvicaFont" :class="!account_active ? 'active' : ''">

            <h4 class="helvicaFont"> <span v-html="text_accesos"></span> ({{loading}}%)</h4>
            <div class="loading">
                <div class="progress" :style="{'width': `${loading}%`, 'background-color': color}"></div>
            </div>
            
        </section>

        <section v-if="account_active" class="container verify-active animation-Garnier helvicaFont" :class="{ 'active-animation': GTen }">
            <div>
                <img src="@/assets/img/logo-10/gar.2.png" alt="">
            </div>
            <div>
                <img src="@/assets/img/logo-10/10.2.png" alt="">
            </div>
            <div>
                <img src="@/assets/img/logo-10/nier.2.png" alt="">
            </div>
        </section>
        <FooterAuth v-if="!account_active"/>
        <section v-if="account_active" class="settings_verify">
        </section>
    </div>
</template>


<script>
import HeaderAuth from '@/components/public/HeaderAuth.vue';
import FooterAuth from '@/components/public/FooterAuth.vue';
import {
    activeAccount,
} from '@/helpers/fetchs/user';
import { encript, generateAESKey } from "@/helpers/secret/encrypt_decrypt";


export default{
    components: {
        HeaderAuth,
        FooterAuth
    },
    data(){
        return{
            token: this.$route.params.token,
            account_active: false,
            loading: 0,
            text_accesos: 'Verificando accesos',
            color: '#FFB600',
            GTen: false,
        }
    },
    methods:{
        async active(){                
                const loading_pause = setInterval(()=>{
                    this.loading += 1;
                    if(this.loading >= 100){
                        clearInterval(loading_pause);
                    }
                }, 200);

                const activeAccountFetch = await activeAccount(this.token)

                    if(activeAccountFetch.status == 201){

                        setTimeout(()=>{
                            this.GTen = true;
                        }, 500)

                        this.loading = 99;
                        this.account_active = true;
                        return activeAccountFetch.json().then( async data=>{
                            const user_data = data.data.data;

                            const newkey = await generateAESKey();

                            // Save key
                            const expires = new Date();
                            expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000); // 1 día en milisegundos

                            // Llave de la sesion
                            document.cookie = `__SESSIONKEY=${newkey};expires=${expires.toUTCString()};path=/`;

                            // Datos del usuario:


                            // Convertir el objeto en una cadena JSON

                            const jsonEncript = await encript(user_data.token, newkey);
                            document.cookie = `__SESSIONDATA=${jsonEncript};expires=${expires.toUTCString()};path=/`;

                            localStorage.removeItem('email_verify')
                            localStorage.removeItem('verify')

                            localStorage.setItem('presentation', 'true');

                            setTimeout( ()=>{
                                this.$router.push({ name: "mi-cuenta" });
                            }, 3000)

                        })
                    }else{

                        localStorage.removeItem('email_verify')
                        localStorage.removeItem('verify')
                        this.loading = 99;
                        this.text_accesos = 'Su cuenta es invalida <i class="bi bi-emoji-frown"></i>'
                        this.color = '#FF0624'
                    }
                    

        },
    },
    created(){

        this.active();
    },
}

</script>