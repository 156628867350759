import router from "@/router/index";
import store from '@/store/index';

const domain = "https://g10-admin.garnierbbdo.com/api/";

import { token } from '@/helpers/getTokeUser';

function close() {
  localStorage.clear();
  store.dispatch('resetUser');
  router.push({ name: "login" });
}


const saveMessage = async (slug, data) => {
  
    return await fetch(`${domain}messages/${slug}`, {
      method: "POST",
      headers: await token(),
      body: JSON.stringify(data),
    }).then((res) => {
        if(res.status == 201 || res.status == 404){
            return res.status;
        }else{
            close();
        }
    });
  };

  const getMessage = async (slug) => {  
    return await fetch(`${domain}messages/${slug}`, {
      method: "GET",
      headers: await token(),
    }).then((res) => {
        if(res.status == 200){
            return res.json().then(data => {
                return data.data.items;
            });
        }else{
            close();
        }
    });
  };


  const socketMessage = async ( data, slug ) => {

    return await fetch(`${domain}messages/${slug}/send`,{
      method: "POST",
      headers: await token(),
      body: JSON.stringify(data)
    }).then( res =>{
        return res;
    }).catch(err => {
      throw new Error(`Error: ${err}`);
    });

  }


  export {
    saveMessage,
    getMessage,
    socketMessage
  };
  