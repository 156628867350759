<template>
    <div>
        <MenuComponent/>
        <section class="container private">
            <HeaderPrivateView/>
            <section class="admin-modificacion">
                <section class="title">
                    <h1>
                        <span>Papelera</span>
                        <br/>
                        Clientes
                    </h1>
                    <ButtonBackComponent nameRouter="clientes" />
                </section>
            </section>
            <section class="container-items trash">
                <section class="all-items" v-if="load">
                    <section class="items-departaments user-modify" v-for="item in items" :key="item.id"> 
                        <span> {{ item.name }} </span>
                        <button class="restaurar" @click="SendPublic(item.id, $event.currentTarget)">
                            <i class="bi bi-arrow-clockwise"></i>
                        </button> 
                    </section>
                </section>
                <section v-else>
                    <SkeletonModal/>
                </section>
            </section>
        </section>
    </div>
</template>

<script>

    // Informacion
    import MenuComponent from '@/components/private/MenuComponent.vue';
    import HeaderPrivateView from '@/components/private/admin/HeaderPrivateView.vue';
    import ButtonBackComponent from '@/components/buttons/back/ButtonBackComponent.vue';
    import { 
        getClientsTrash,
        RestaurarClients,
    } from "@/helpers/fetchs/clients";
    import SkeletonModal from "@/components/extras/SkeletonModal.vue";

    // Notifty
    import { useToast } from 'vue-toastification';



    export default {
        components: {
            MenuComponent,
            HeaderPrivateView,
            SkeletonModal,
            ButtonBackComponent
        },
        data() {
            return{
                items: null,
                load: false,
            }
        },
        methods: {
            async getClients(){

                const clientsTrash = await getClientsTrash();
                this.items = clientsTrash;
                this.load = true;

            },

            async SendPublic(id, event){

                let items = {
                    id: id,
                }

                event.disabled = true;

                const toast = useToast();

                const restaurar = await RestaurarClients(items);

                if(restaurar.status == 202){

                    toast.success('El Cliente se restauro con éxito.',{ 
                        position: 'bottom-right',
                        timeout: 5000,
                        pauseOnHover: true,
                        hideProgressBar: true,
                        icon: false,
                        closeButton: false
                    });
                    this.getClients();
                }else{
                    event.disabled = false;
                    toast.error('Tenemos problemas para restaurar este Cliente, por favor inténtelo más tarde.',{ 
                        position: 'bottom-right',
                        timeout: 5000,
                        pauseOnHover: true,
                        hideProgressBar: true,
                        icon: false,
                        closeButton: false
                    });
                }
            },

        },
        created(){
            this.getClients();
        }
    }

</script>