<template>
    <div>
        <div v-for="item in skeletonItems" :key="item" class="skeleton-items">
            <n-space vertical>
                <n-skeleton height="40px" width="33%" />
                <n-skeleton height="60px" width="100%" :sharp="false" />
            </n-space>
        </div>
    </div>
</template>


<script>

    export default{
        data(){
            return{
                skeletonItems: 3
            }
        }
    }

</script>
