<template>
  <div>
    <MenuComponent />
    <section class="container private">
      <section class="form_my_account">
        <HeaderAuth class="logo_garnier" />
        <form class="edit-user">
          <label for="new_photo" :disabled="uploading" class="photo">
            <section class="profile">
              <section
                v-if="!microsoft"
                class="change_photo"
                :class="uploading ? 'active' : ''"
              >
                <i v-if="!uploading" class="bi bi-pencil"></i>
                <n-spin v-else size="large" />
              </section>
              <section
                class="image_profile"
                v-if="this.data.photo"
                :style="{ 'background-image': 'url(' + data.photo + ')' }"
              ></section>
              <img v-else src="@/assets/img/profile.png" alt="" />
            </section>
            <input
              type="file"
              id="new_photo"
              :disabled="uploading || microsoft"
              accept="image/*"
              @change="uploadPhoto"
            />
          </label>
          <section class="info-user">
            <h1 class="change_microsoft">
              Perfil
              <n-popover trigger="hover">
                <template #trigger>
                  <n-switch v-model:value="switchValue" @click="changeModal" />
                </template>
                <span>{{ textSwith }}</span>
              </n-popover>
            </h1>
            <section class="column">
              <label for="name" class="relative_inputs helvicaFont">
                <section class="relative_inputs helvicaFont">
                  <input
                    type="text"
                    id="name"
                    v-model="this.value.name"
                    placeholder="Nombre"
                    v-on:focus="focus_input($event)"
                    v-on:blur="blur_input($event)"
                  />
                </section>
                <div class="error" />
              </label>
              <label for="lastName" class="relative_inputs helvicaFont">
                <section class="relative_inputs helvicaFont">
                  <input
                    type="text"
                    id="lastName"
                    v-model="this.value.lastName"
                    placeholder="Apellidos"
                    v-on:focus="focus_input($event)"
                    v-on:blur="blur_input($event)"
                  />
                </section>
                <div class="error" />
              </label>
            </section>
            <label for="email" class="relative_inputs helvicaFont">
              <section class="relative_inputs helvicaFont">
                <input
                  type="email"
                  id="email"
                  v-model="this.value.email"
                  placeholder="Correo electrónico"
                  v-on:focus="focus_input($event)"
                  v-on:blur="blur_input($event)"
                  :disabled="emailActive"
                />
              </section>
              <div class="error" />
            </label>
            <section class="column-min">
              <label
                v-if="options"
                for="name"
                class="selected"
                :disabled="selectDisabled"
              >
                <n-space vertical :disabled="selectDisabled">
                  <n-select
                    :disabled="selectDisabled"
                    v-model:value="value.team.name"
                    filterable
                    placeholder="Seleccionar departamento"
                    :options="options"
                  />
                </n-space>
              </label>
              <button
                :disabled="selectDisabled"
                @click="updateUser"
                type="button"
                class="send_move item"
              >
                <svg width="25" viewBox="0 0 45 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.9 40.17L43.44 20.59L24.9 1" stroke="white" stroke-miterlimit="10"/>
                    <path d="M43.45 20.59H0" stroke="white" stroke-miterlimit="10"/>
                </svg>

              </button>
            </section>
          </section>
        </form>
      </section>
    </section>
    <ModalComponent
      :view="modalEvent"
      :close="cerrarSesion"
      :updated="updatedFiles"
      :presentation="viewPresentation"
      @cambio-cancelado="recibirDatos"
      @login-actualizado="loginUpdate"
    />
  </div>
</template>

<script>
import MenuComponent from "@/components/private/MenuComponent.vue";
import HeaderAuth from "@/components/public/HeaderAuth.vue";
import {
  updateMyAccound,
} from "@/helpers/fetchs/user";
import {
  teamsPublic
} from "@/helpers/fetchs/teams";
import ModalComponent from "@/components/extras/modals/ModalComponent.vue";
import validation from "@/helpers/validation";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: {
    MenuComponent,
    HeaderAuth,
    ModalComponent,
  },
  data() {
    return {
      data: {},
      value: {
        name: "",
        lastName: "",
        email: "",
        team: {}, // Inicializar como objeto vacío
      },
      options: [],
      switchValue: false,
      textSwith: this.switchValue
        ? "Desactivar autenticación con Microsoft"
        : "Activar autenticación con Microsoft",
      uploading: false,
      colorLoading: "$bs-success",
      colorLoaginOpacity: "#333333",
      colorLoaginOpacityTwo: "#dffff2",
      microsoft: true,
      modalEvent: null,

      validation_all: {
        inputs: 0,
        email: "",
      },
      selectDisabled: false,
      cerrarSesion: false,
      emailActive: true,
      updatedFiles: '',
      viewPresentation: false,
    };
  },
  methods: {
    async accountData() {


      await this.$store.dispatch('getUser').then( () => {
        const user = this.$store.state.user;

        this.value.name = user.name;
        this.value.lastName = user.lastName;
        this.value.email = user.email;
        this.data.photo = user.photo;


        if (user.team) {
          this.value.team = user.team;
        }

        if (user.microsft == "true") {
          this.microsoft = true;
          this.switchValue = true;
          this.emailActive = true;
        } else {
          this.emailActive = false;
          this.microsoft = false;
          this.switchValue = false;
        }

      });

      
    },

    changeModal() {

      if (this.switchValue == false) {
        this.modalEvent = false;
      } else if (this.switchValue == true) {
        this.modalEvent = true;
      }
      
    },

    async departamantos() {

      const allTeams = await teamsPublic();

      this.options = allTeams;

    },

    uploadPhoto(event) {

      const file = event.target.files[0];

      if (file) {
        if (
          (file.type == "image/png" ||
            file.type == "image/svg+xml" ||
            file.type == "image/jpeg") &&
          file.size <= 307200
        ) {
          this.uploading = true;

          // Convert to code 64;

          const reader = new FileReader();

          reader.onload = async (e) => {
            const code = e.target.result;

            const data = {
              photo: code,
            };

            this.data.photo = code;

            this.updateAll(data);

          };

          reader.readAsDataURL(file);
        } else if (file.size > 307200) {
          toast.error("La imagen no puede pesar más de 300KB.", {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
        } else {
          toast.error("Esta imangen no es permitida.", {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
        }
      }

      localStorage.clear();

    },

    recibirDatos() {
      this.accountData();
      this.modalEvent = null;
      setTimeout(() => {
        this.viewPresentation = false;
      }, 500)
    },

    loginUpdate(value) {
      if (value) {
        toast.success("Inicio de sesión con Microsoft activado.", {
          position: "bottom-right",
          timeout: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });
      } else {
        toast.success("La contraseña se establecio con exíto.", {
          position: "bottom-right",
          timeout: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });
      }
      this.accountData();
    },

    focus_input(element) {
      const element_child = element.target.parentNode;
      element_child.classList.add("active");
    },

    blur_input(element) {
      const element_child = element.target.parentNode;
      const element_input = element.target;
      const type = "individual";

      element_input.value
        ? element_child.classList.add("active")
        : element_child.classList.remove("active");

      validation(element_input, type);
    },

    validation() {
      this.validation_all.inputs = 0;
      const elements_child = document.querySelectorAll("input");

      const type = "individual";
      elements_child.forEach((e) => {
        if (!validation(e, type)) {
          this.validation_all.inputs += 1;
        }
      });
    },

    async updateAll(data) {

      this.updatedFiles = 'update';

      this.updated = true;
      this.modalEvent = this.modalEvent ? false : true;

      const update = await updateMyAccound(data);

      this.uploading = false;

      const elements_child = document.querySelectorAll("input");
      this.selectDisabled = false;
      elements_child.forEach((e) => {
        if (e.type == "email") {
          if (!this.emailActive) {
            e.disabled = false;
          }
        } else {
          e.disabled = false;
        }
      });
      
      this.updatedFiles = 'updated';

      setTimeout(() => {
        this.updatedFiles = '';
      }, 500)

      if(update.status == 202){

        toast.success("El usuario se actualizo con exíto.", {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
        });

        return update.text().then((text) => {
            if (text) {
              const data = JSON.parse(text);
              if (data.data.verification == "Need to verify") {
                this.cerrarSesion = true;
              }
            }else{
              this.$store.dispatch('getUser');
            }
        });

      }else if (update.status == 422) {
        toast.error("Este correo electrónico ya esta registrado.", {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });

      }else{

        toast.error("El usuario no se pudo actualizar.", {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });

      }

    },

    async updateUser() {

      this.validation();

      if (this.validation_all.inputs == 3 || this.validation_all.inputs == 4) {

        const elements_child = document.querySelectorAll("input");
        this.selectDisabled = true;
        elements_child.forEach((e) => {
          e.disabled = true;
        });

        let data = {};

        if (this.emailActive) {
          if (!isNaN(this.value.team.name)) {
            data = {
              name: this.value.name,
              lastName: this.value.lastName,
              team: this.value.team.name,
            };

            this.updateAll(data);
          } else {
            data = {
              name: this.value.name,
              lastName: this.value.lastName,
            };

            this.updateAll(data);
          }
        } else {
          if (!isNaN(this.value.team.name)) {
            data = {
              name: this.value.name,
              lastName: this.value.lastName,
              email: this.value.email,
              team: this.value.team.name,
            };

            this.updateAll(data);
          } else {
            data = {
              name: this.value.name,
              lastName: this.value.lastName,
              email: this.value.email,
            };

            this.updateAll(data);
          }
        }
      }
    },
    presentation(){
      if(localStorage.getItem("presentation") == "true"){
          this.modalEvent = true;
          this.viewPresentation = true  ;
      }
    }
  },
  computed: {
            
      user() {
          return this.$store.state.user;
      }

  },
  watch: {
    switchValue(newValue) {
      if (newValue) {
        this.textSwith = "Desactivar autenticación con Microsoft.";
      } else {
        this.textSwith = "Activar autenticación con Microsoft.";
      }
    },
  },
  mounted() {
    this.presentation();
  },
  created() {
    this.accountData();
    this.departamantos();
  },
};
</script>

