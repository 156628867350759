<template>
    <div>
        <MenuComponent/>
        <section class="container private">
            <HeaderPrivateView/>
            <section class="admin-modificacion">
                <section class="title">
                    <h1>
                        <span>Papelera</span>
                        <br/>
                        Problemáticas pendientes
                    </h1>
                    <ButtonBackComponent nameRouter="problemas-pendientes" />
                </section>
            </section>
            <div>
                <section class="problems-items" v-if="load">
                    <section v-for="item in items" class="problem-item" :key="item.id">
                        <section class="items-content">
                            <section>
                                <h2>{{ item.title }}</h2>
                                <p>{{ item.data }}</p>
                            </section>
                        </section>
                        <section class="group-bottons-remove">
                            <button class="action-problems-pendient papelera" @click="moveProblematica(item.slug, $event.currentTarget)">
                                <i class="bi bi-arrow-clockwise"></i>
                            </button>
                        </section>
                    </section>
                </section>
                <section v-else>
                    <SkeletonProblems/>
                </section>
            </div>
        </section>
    </div>
</template>

<script>
    import MenuComponent from '@/components/private/MenuComponent.vue';
    import HeaderPrivateView from '@/components/private/admin/HeaderPrivateView.vue';
    import SkeletonProblems from '@/components/extras/SkeletonProblems.vue';
    import ButtonBackComponent from '@/components/buttons/back/ButtonBackComponent.vue';
    import {
        updateProblem,
        problemsRemove
    } from '@/helpers/fetchs/problems';
    import { useToast } from "vue-toastification";

    const toast = useToast();

    export default{

        components:{
            MenuComponent,
            HeaderPrivateView,
            SkeletonProblems,
            ButtonBackComponent
        },
        data(){
            return{
                items: {},
                load: false,
            }
        },
        methods: {
            async problems(){

                const problemsItems = await problemsRemove();
                
                this.load = true;

                this.items = problemsItems;

            },

            async moveProblematica(slug, tag){

                tag.disabled = true;

                const data = {
                    active: 1
                }

                const updateProblems =  await updateProblem(data, slug);

                if(updateProblems.status == 202){

                    this.problems();

                    toast.success(`El problema se restableció  con éxito.`, {
                        position: "bottom-right",
                        timeout: 5000,
                        pauseOnHover: true,
                        hideProgressBar: true,
                        icon: false,
                        closeButton: false,
                    });

                    return;
                }

                toast.error(`Tenemos problemas para restablecer el problema, por favor inténtelo más tarde.`, {
                    position: "bottom-right",
                    timeout: 5000,
                    pauseOnHover: true,
                    hideProgressBar: true,
                    icon: false,
                    closeButton: false,
                });

                tag.disabled = false;

            },
        },
        created(){
            this.problems();
        }

    }

</script>