import router from "@/router/index";
import store from '@/store/index';
import { token } from '@/helpers/getTokeUser';

const domain = "https://g10-admin.garnierbbdo.com/api/";

function close() {
  localStorage.clear();
  store.dispatch('resetUser');
  router.push({ name: "login" });
}

const teamsPublic = async () => {
  
  return await fetch(`${domain}team-public`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 200) {
      return res.json().then((data) => {
        return data.data.items;
      });
    } else {
      close();
    }
  });
};

const teams = async () => {

  return await fetch(`${domain}team`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 200) {
      return res.json().then((data) => {
        return data.data.items;
      });
    } else {
      close();
    }
  });
};

const saveDepartaments = async (data) => {
 
  return await fetch(`${domain}team`, {
    method: "POST",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

const updateDepartaments = async (data) => {

  return await fetch(`${domain}team`, {
    method: "PUT",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

// Delete team

const deleteDepartaments = async (data) => {

  return await fetch(`${domain}team`, {
    method: "DELETE",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

export { teams, saveDepartaments, updateDepartaments, deleteDepartaments, teamsPublic };
