import router from "@/router/index";
import store from '@/store/index';

const domain = "https://g10-admin.garnierbbdo.com/api/";
import { token } from '@/helpers/getTokeUser';

function close() {
  localStorage.clear();
  store.dispatch('resetUser');
  router.push({ name: "login" });
}

const problemsAllApproved = async (data = null) => {
 
  const params = new URLSearchParams(data).toString();

  return await fetch(`${domain}problems?${params}`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 200 || res.status == 202) {
      return res.json().then((data) => {
        return data.data.items;
      });
    } else if(res.status != 404){
      close();
    }else{
      return 404;
    }
  });
};

const problemsApprovedIndividual = async (data = null) => {

  return await fetch(`${domain}problems-aprovate/${data}`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {

    if (res.status == 200 || res.status == 202) {
      return res.json().then((data) => {
        return data.data.item;
      });
    } else if(res.status != 404){
      close();
    }else{
      return 404;
    }
  });

};

const updateUserProblem = async (slug = null, data = null) => {
 
  return await fetch(`${domain}problems-aprovate/${slug}`,{
    method: "POST",
    headers: await token(),
    body: JSON.stringify(data)
  }).then(res => {
    return res;
  });
}


export { problemsAllApproved, problemsApprovedIndividual, updateUserProblem };
