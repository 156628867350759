// Encript the status or the administration

const encript = async (value, secretKey) => {

    // Validate if haven´t a error

    if (!secretKey || !value) {
      location.reload();
      throw new Error('No se ha proporcionado una clave secreta');
    }

    // Initial the encriptation

    const textEncoder = new TextEncoder();
    const encodedValue = textEncoder.encode(value);
    const initializationVector = crypto.getRandomValues(new Uint8Array(16));
  
    // Create the params for the encriptation

    const cryptoKey = await crypto.subtle.importKey(
      "raw",
      secretKey.buffer, // Convierte el Uint8Array en un ArrayBuffer
      "AES-CBC",
      false,
      ["encrypt"]
    );

    // Encryptation of data
  
    const encryptedData = await crypto.subtle.encrypt(
      {
        name: "AES-CBC",
        iv: initializationVector,
      },
      cryptoKey,
      encodedValue
    );
  
    const encryptedArray = new Uint8Array([
      ...initializationVector,
      ...new Uint8Array(encryptedData),
    ]);
  
    // Return data encriptation

    return btoa(String.fromCharCode(...encryptedArray));
};
  
  

const descript = async (encryptedCode, secretKey) => {

    try{

        // Starting description
        
        const textDecoder = new TextDecoder();
        const encryptedArray = new Uint8Array(atob(encryptedCode).split('').map((c) => c.charCodeAt(0)));

      // Get value encripted

        const initializationVector = encryptedArray.slice(0, 16);
        const encryptedData = encryptedArray.slice(16);

        // Validate data encripted

        const cryptoKey = await crypto.subtle.importKey(
            "raw",
            secretKey,
            "AES-CBC",
            false,
            ["decrypt"]
        );

        // Decrypted data with a vector

        const decryptedData = await crypto.subtle.decrypt(
            {
            name: "AES-CBC",
            iv: initializationVector,
            },
            cryptoKey,
            encryptedData
        );

        // Return the value decoded 

        const decodedValue = textDecoder.decode(decryptedData);
        return decodedValue;

    }catch{

        // If have a error close session
        sessionStorage.clear();

    }

};

// Create keys for the tokens

const generateAESKey = () => {
    const key = new Uint8Array(16); // 128 bits (16 bytes)
    crypto.getRandomValues(key);
    return key;
};
  

export {
    encript,
    descript,
    generateAESKey
}
