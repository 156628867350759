<template>
    <div class="login verify center h-v-100">
        <HeaderAuth/>
        <section class="container">
            <p class="text-center helvicaFont verify-text">Te enviamos a la dirección <a class="email" :href="`mailto:${email}`">{{ email }}</a>  <br/> un correo de confirmación.</p>
        </section>

        <span class="helvicaFont">
            ¿No te llegó?  <button class="helvicaFont" :disabled="disable" v-on:click="send_email">{{ text }}</button>
        </span>

    </div>
</template>


<script>
import HeaderAuth from '@/components/public/HeaderAuth.vue';
import {
    sendVerify
} from '@/helpers/fetchs/user';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default{
    components: {
        HeaderAuth,
    },
    data(){
        return{
            email: localStorage.getItem('email_verify'),
            text: 'Reintentalo',
            disable: false,
        }
    },
    methods: {

        // Send email for the user
        async send_email(){
            
            // Get email of the user
            const form = new FormData();
            form.append('email', this.email);

            this.text = 'Reenviando...';
            this.disable = true

            // Sendig email 

            const newVerify = await sendVerify(form);

            if(newVerify.status == 201){

                toast.success('El correo se ha enviado con éxito.',{ 
                    position: 'bottom-right',
                    timeout: 5000,
                    pauseOnHover: true,
                    hideProgressBar: true,
                    icon: false,
                    closeButton: false
                });

                // The user have 60 second for resent the email

                let count = 59;
                this.text = `Reintentar en ${count}`;
                this.disable = true
                const send_message = setInterval(()=>{
                    count -= 1;
                    if(count < 0){
                        clearInterval(send_message);
                        this.disable = false;
                        this.text = 'Reintentalo'
                    }else{
                        this.text = `Reintentar en ${count}`;
                        this.disable = true
                    }

                }, 1000)

            }else{

                // The send of emai is incorrect

                this.text = 'Reintentalo'
                this.disable = false;

                toast.error('Hubo un error al enviar su correo',{ 
                    position: 'bottom-right',
                    timeout: 5000,
                    pauseOnHover: true,
                    hideProgressBar: true,
                    icon: false,
                    closeButton: false
                });
            }
        }
    },
    created(){
        if(!this.email){

            // If email not exist redirect to not found page

            this.$router.push({name: 'not-found-redirect'})
        }
    }
}

</script>