<template>
  <div class="problems-private-background">
    <MenuComponent />
    <ModalProblemsCreateView v-if="sending" :notify="notify" :typeModal="typeModal" :successful="finalizado"
      @closeModal="closeModal" />
    <section class="container private problem-individual">
      <div class="header-admin">
        <img src="@/assets/img/garnier-logo-blanco.svg" alt="">
      </div>
      <section v-if="load">
        <section class="problem-header-individual">
          <h1>
            <span @blur="saveItems('name', $event)" contenteditable="true">
              {{ item.name }}
            </span>
            +
            <n-select v-model:value="selectValue" filterable placeholder="Seleccione el cliente" :options="clients"
              class="items-select" />
          </h1>
          <section>
            <section class="actions">
              <button class="action-problems-pendient" @click="aprobarProblema">
                <i class="bi bi-check2"></i>
              </button>
              <button class="action-problems-pendient" @click="removeProblema">
                <i class="bi bi-trash3"></i>
              </button>
              <section class="users-items">
                <section v-if="item.create_by_id_user && item.create_by_id_user.photo" class="user_photo user-modify"
                  :style="{ backgroundImage: 'url(' + item.create_by_id_user.photo + ')' }">
                </section>

                <section v-if="item.update_by_id_user && item.update_by_id_user.photo" class="user_photo user-modify"
                  :style="{ backgroundImage: 'url(' + item.update_by_id_user.photo + ')' }">
                </section>
              </section>
            </section>
          </section>
        </section>
        <section>
          <h2>Data e información de caso</h2>
          <p contenteditable="true" @blur="saveItems('content', $event)">{{ item.data }}</p>
        </section>
        <section class="items-selected">
          <section>
            <h3>Links</h3>
            <n-dynamic-input v-model:value="linksValue" placeholder="Inserte el enlace" :min="1" :max="6" />
          </section>
          <section class="miro">
            <h3>Miro</h3>
            <section>
              <input @blur="saveItems('miro', $event)" type="text" :value="item.miro" />
            </section>
          </section>
          <section>
            <h3>Temáticas</h3>
            <n-tree-select multiple cascade checkable :options="themes" :default-value="themesDefault"
              v-model:value="themesValues" />
          </section>
        </section>
      </section>
      <section class="problem-individual skeleton-container" v-else>
      <SkeletonProblemsIndividual />
    </section>
    </section>
  </div>
</template>

<script>
import MenuComponent from "@/components/private/MenuComponent.vue";
import SkeletonProblemsIndividual from "@/components/extras/SkeletonProblemsIndividual.vue";
import {
  problemsIndividualNotApproved,
  updateProblem,
  updateProblemTheme
} from "@/helpers/fetchs/problems";
import {
  getClients
} from "@/helpers/fetchs/clients";
import {
  teams,
} from "@/helpers/fetchs/themes";
import ModalProblemsCreateView from '@/components/private/public/ModalProblemsCreateView.vue';

import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    MenuComponent,
    ModalProblemsCreateView,
    SkeletonProblemsIndividual
  },
  data() {
    return {
      item: {},
      editNameDisabled: false,
      clients: [],
      themes: [],
      themesDefault: [],
      routerParam: this.$route.params.slug,
      selectValue: '',
      linksValue: [],
      themesValues: [],
      finalizado: false,
      typeModal: 'save',
      sending: false,
      load: false,
    };
  },
  methods: {

    async problemView() {
      const slug = this.$route.params.slug;

      const individual = await problemsIndividualNotApproved(slug);

      this.item = individual;
      this.selectValue = this.item.client.value;
      this.linksValue = this.item.links;
      this.getClients();
      this.getThemes();

    },

    saveItems(type, event) {

      let data;
      let content = event.target.textContent;

      if (type == 'name') {

        if (this.item.name != content && content) {
          data = {
            title: content,
          }
          this.updatePrblem(data);
        }
      } else if (type == "content") {
        if (this.item.data != content && content) {
          data = {
            data: content,
          }
          this.updatePrblem(data);
        }
      } else if (type == "miro") {
        content = event.target.value;

        if (this.item.miro != content && content) {
          data = {
            miro: content,
          }
          this.updatePrblem(data);
        }

      }


    },

    async updatePrblem(data) {



      const update = await updateProblem(data, this.routerParam);

      this.problemView();
      if (update.status == 202) {
        toast.success(`El problema se actualizo con éxito.`, {
          position: "bottom-right",
          timeout: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });
      } else if (update.status == 422) {
        toast.error(`Esta problemática ya existe`, {
          position: "bottom-right",
          timeout: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });
      } else if (update.status == 400) {
        toast.error(`Verifique que los enlaces sean validos`, {
          position: "bottom-right",
          timeout: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });
      } else {
        toast.error(`Tenemos problemas actualizar el problema, por favor inténtelo más tarde.`, {
          position: "bottom-right",
          timeout: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });
      }

    },

    async getClients() {

      const allClients = await getClients();

      const transformObject = Object.keys(allClients).map((key) => {
        const { id, name, ...rest } = allClients[key];
        return {
          value: id,
          label: name,
          ...rest,
        };
      });

      this.clients = transformObject;
      this.linksUpdate();

    },
    async getThemes() {

      const teamsItems = await teams();

      const defaultThemes = await this.item.themes;
      this.load = true;

      Object.keys(defaultThemes).map((key) => {
        const { id } = defaultThemes[key];
        this.themesDefault.push(id);
      });

      this.themesValues = this.themesDefault;

      const transformObject = Object.keys(teamsItems).map((key) => {
        const { id, name } = teamsItems[key];
        return {
          label: name,
          key: id,
        };
      });

      this.themes = transformObject;

    },


    async aprobarProblema() {

      this.sending = true;
      this.typeModal = 'save';

      const data = {
        approved: 1
      }

      const update = await updateProblem(data, this.routerParam)

      if (update.status == 202) {

        this.finalizado = !this.finalizado ? true : false;
        this.sending = false;
        toast.success(`La problemática se aprobó con éxito.`, {
          position: "bottom-right",
          timeout: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });

        this.$router.push({ name: 'problemas-pendientes' })

        return;
      }

      this.sending = false;

      toast.error(`Tenemos problemas para aprobar el problema, por favor inténtelo más tarde.`, {
        position: "bottom-right",
        timeout: 5000,
        pauseOnHover: true,
        hideProgressBar: true,
        icon: false,
        closeButton: false,
      });
    },

    async removeProblema() {

      this.sending = true;
      this.typeModal = 'delete';

      const data = {
        active: 0
      }

      const update = await updateProblem(data, this.routerParam)

      if (update.status == 202) {

        this.finalizado = !this.finalizado ? true : false;
        this.sending = false;

        toast.success(`La problemática se elimino con éxito.`, {
          position: "bottom-right",
          timeout: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });
        this.$router.push({ name: 'problemas-pendientes' })

        return;
      }

      this.sending = false;

      toast.error(`Tenemos problemas para  enviar este problems a la papelera, por favor inténtelo más tarde.`, {
        position: "bottom-right",
        timeout: 5000,
        pauseOnHover: true,
        hideProgressBar: true,
        icon: false,
        closeButton: false,
      });
    },
    linksUpdate() {

      // Esta funcion se hace para ejecutar al funcion al momento de cargar la pagina, de esta forma se esta se agregar el setTimeOut, para esperar que el client cargue de forma completa
      setTimeout(() => {

        const menuBoton = document.querySelectorAll('.n-button-group');

        menuBoton.forEach(e => {
          e.childNodes[0].onclick = () => {
            this.linksValue;
          }
        })

      }, 500)
    }

  },
  watch: {
    async selectValue(newVal, oldValue) {
      if (oldValue) {

        const data = {
          id_client: newVal
        }
        await this.updatePrblem(data);
        await this.problemView();

      }
    },

    linksValue(newVal, oldValue) {
      const arraylink = [];
      if (oldValue.length) {

        const inputs = document.querySelectorAll('.n-input__input-el');
        const menuBoton = document.querySelectorAll('.n-button-group');

        menuBoton.forEach(e => {
          e.childNodes[0].onclick = () => {

            if (JSON.stringify(newVal) != JSON.stringify(oldValue)) {

              newVal.forEach(e => {
                arraylink.push(e);
              })
              const data = {
                links: arraylink.join()
              }
              this.updatePrblem(data);

            }

          }
        })

        inputs.forEach(e => {
          e.onblur = () => {

            if (JSON.stringify(newVal) != JSON.stringify(oldValue)) {

              newVal.forEach(e => {
                arraylink.push(e);
              })
              const data = {
                links: arraylink.join()
              }
              this.updatePrblem(data);

            }

          }
        })
      }
    },

    async themesValues(newVal) {

      const defaultThemes = this.item.themes;
      Object.keys(defaultThemes).map((key) => {
        const { id } = defaultThemes[key];
        this.themesDefault.push(id);
      });

      if (JSON.stringify(newVal) != JSON.stringify(this.themesDefault)) {

        const data = {
          id_themes: newVal.join()
        }

        const problemsThemes = await updateProblemTheme(data, this.routerParam);

        if (problemsThemes.status == 202) {
          toast.success(`El problema se actualizo con éxito.`, {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });

        } else {

          toast.error(`Tenemos problemas actualizar el problema, por favor inténtelo más tarde.`, {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });

        }
      }
    },

    closeModal() {
      this.sending = false;
    }

  },
  created() {
    this.problemView();
  },
};
</script>

<style scoped>
.problems-private-background {
  background-color: #00CC79;
  min-width: 100vh;
  min-height: 100vh;
}

section.container.private.problem-individual.skeleton-container {
  position: fixed;
}
</style>
