<template>
    <div>
        <MenuComponent/>
        <ModalProblemsCreateView v-if="sending" :notify="notify" :typeModal="typeModal" :successful="finalizado" @closeModal="closeModal"/>
        <section class="container private">
            <HeaderPrivateView/>
            <section class="admin-modificacion">
                <section class="title">
                    <h1>
                        Problemáticas 
                        <br/>
                        pendientes
                    </h1>
                    <ButtonTrashComponent nameRoute="problemas-pendientes-papelera" />
                </section>
            </section>
            <div>
                <section class="problems-items" v-if="load" >
                    <section v-for="item in items" class="problem-item" :key="item.id">
                        <section class="items-content">
                            <section>
                                <h2>{{ item.title }}</h2>
                                <p>{{ item.data }}</p>
                            </section>
                        </section>
                        <section class="group-bottons">
                            <button class="action-problems-pendient" @click="aprobarProblematica(item.slug)">
                                <i class="bi bi-check2"></i>
                            </button>
                            <router-link :to="{name: 'problemas-pendientes-individual', params: {slug: item.slug}}" class="action-problems-pendient">
                                <i class="bi bi-pencil"></i>
                            </router-link>
                            <button class="action-problems-pendient" @click="deleteProblematica(item.slug)">
                                <i class="bi bi-trash3"></i>
                            </button>
                        </section>
                    </section>
                </section>
                <SkeletonProblems v-else/>
            </div>
        </section>
    </div>
</template>

<script>
    import MenuComponent from '@/components/private/MenuComponent.vue';
    import HeaderPrivateView from '@/components/private/admin/HeaderPrivateView.vue';
    import {
        problemsNotApproved,
        updateProblem
    } from '@/helpers/fetchs/problems';
    import ModalProblemsCreateView from '@/components/private/public/ModalProblemsCreateView.vue';
    import SkeletonProblems from '@/components/extras/SkeletonProblems.vue';
    import ButtonTrashComponent from '@/components/buttons/trash/ButtonTrashComponent.vue';
    import { useToast } from "vue-toastification";

    const toast = useToast();

    export default{

        components:{
            MenuComponent,
            HeaderPrivateView,
            ModalProblemsCreateView,
            SkeletonProblems,
            ButtonTrashComponent
        },
        data(){
            return{
                items: {},
                notify: false,
                finalizado: false,
                typeModal: 'save',
                sending: false,
                load: false,
            }
        },
        methods: {
            async problems(){
                
                const problems = await problemsNotApproved();
                this.items = problems;
                this.load = true;

            },

            async aprobarProblematica(slug){

                this.sending = true;
                this.typeModal = 'save';

                const data = {
                    approved: 1
                }

                const updateProblems = await updateProblem(data, slug);


                if(updateProblems.status == 202){
                    this.problems();
                    this.finalizado = !this.finalizado ? true : false;
                    return;
                }

                this.sending = false;

                toast.error(`Tenemos problemas para aprobar el problema, por favor inténtelo más tarde.`, {
                    position: "bottom-right",
                    timeout: 5000,
                    pauseOnHover: true,
                    hideProgressBar: true,
                    icon: false,
                    closeButton: false,
                });

            },

            async deleteProblematica(slug){

                this.sending = true;
                this.typeModal = 'delete';

                const data = {
                    active: 0
                }

                const updateProblems = await updateProblem(data, slug);

                if(updateProblems.status == 202){

                    this.problems();
                    this.finalizado = !this.finalizado ? true : false;

                    return;
                }

                this.sending = true;

                toast.error(`Tenemos problemas para  enviar este problems a la papelera, por favor inténtelo más tarde.`, {
                    position: "bottom-right",
                    timeout: 5000,
                    pauseOnHover: true,
                    hideProgressBar: true,
                    icon: false,
                    closeButton: false,
                });

            },

            closeModal(){
                this.sending = false;
            }
        },
        created(){
            this.problems();
        }

    }

</script>