<template>
  <div>
    <MenuComponent />
    <section class="container private">
      <section class="indivual-problems">
        <HeaderAuth class="logo_garnier" />
        <section class="container">
          <RouterLink to="/" class="volverProblems">
            <i class="bi bi-arrow-left"></i>
          </RouterLink>

          <section class="swith-problems">
              <p v-if="switchValue">
                <strong>{{ userWorking }}</strong>
                lo está trabajando.
              </p>
              <n-popover trigger="hover" :disabled="updateUserProblem">
                <template #trigger>
                  <n-switch v-model:value="switchValue" @click="changeLogin" :disabled="updateUserProblem"/>
                </template>
                <span>{{ textSwith }}</span>
              </n-popover>
          </section>
            <n-tabs
              class="card-tabs"
              default-value="detalle"
              size="large"
              animated
              pane-wrapper-style="margin: 0 -4px"
              pane-style="padding-left: 4px; padding-right: 4px; box-sizing: border-box;"
            >
                <n-tab-pane name="detalle" tab="Detalle">
                    <ContainerProblemView :updateCollaborators="updateUserProblem"/>
                </n-tab-pane>
                <n-tab-pane name="mesajes" tab="Mensajes">
                    <MessagesProblemView/>
                </n-tab-pane>
            </n-tabs>
        </section>
      </section>
    </section>

    

  </div>
</template>

<script>
import MenuComponent from "@/components/private/MenuComponent.vue";
import HeaderAuth from "@/components/public/HeaderAuth.vue";
import ContainerProblemView from "@/components/private/public/problems/ContainerProblemView.vue";
import MessagesProblemView from "@/components/private/public/problems/MessagesProblemView.vue"
import { updateUserProblem, problemsApprovedIndividual } from "@/helpers/fetchs/publicProblems";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: {
    MenuComponent,
    HeaderAuth,
    ContainerProblemView,
    MessagesProblemView,
  },
  data(){
    return{
      switchValue: false,
      textSwith: "Trabajar esta problemática",
      paramsUrl: this.$route.params.slug,
      activeProblem: {},
      userWorking: '',
      updateUserProblem: false,
    }
  },
  methods:{
    async changeLogin(){

      this.updateUserProblem = true;

      let data = {}

      if(this.switchValue){
        data = {
          active: 1,
        };
      }else{
        data = {
          active: 0,
        };
      }

      const updateProblemUser = await updateUserProblem(this.paramsUrl, data);

      if(updateProblemUser.status){
        this.getProblem();
        let textNotification = '';
        if(this.switchValue){
          textNotification = "Se agrego la problemática con exíto.";
        }else{
          textNotification = "Se elimino la problemática con exíto.";
        }

        toast.success(textNotification, {
          position: "bottom-right",
          timeout: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });

      }else{
        toast.success("No se pudo agregar la problemática.", {
          position: "bottom-right",
          timeout: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });
      }

    },

    async getProblem(){

      const problem = await problemsApprovedIndividual(this.paramsUrl); 
      this.activeProblem = problem;

      this.switchValue = this.activeProblem.working;
      this.userWorking = `${this.$store.state.user.name} ${this.$store.state.user.lastName}`;

      if(this.switchValue){
        this.textSwith = "Dejar de trabajar esta problemática";
      }else{
        this.textSwith = "Trabajar esta problemática";
      }

      this.updateUserProblem = false;

    }

  }, 
  created(){
    this.getProblem();
  }
};
</script>
