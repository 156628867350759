import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { descript } from "@/helpers/secret/encrypt_decrypt";

// Import all views in the file views-routes

import {
  HomeView,
  MyAccountView,
  DepartamentsView,
  ClientsView,
  ClientsTrashView,
  NoAutorizadoView,
  ThemesView,
  ThemesTrashView,
  ProblemsPendintsView,
  ProblemsPendintsPapeleraView,
  ProblemsPendintsIndividualView,
  CreateView,
  VerifyView,
  NotFountView,
  ActiveAccountView,
  LoginView,
  UnlockAccountView,
  ResetPasswordView,
  CreateNewPasswordView,
  NewProblemView,
  FiltersView,
  FiltersTrashView,
  ProblemIndividualView,
  HomeAdminView,
} from "@/containerImports/views-routes.js";

// All routes of views

const routes = [
  {
    path: "/",
    name: "inicio",
    component: HomeView,
    meta: {
      guard: ["auth"],
    },
  },
  {
    path: "/home",
    name: "admin_inicio",
    component: HomeAdminView,
    meta: {
      guard: ["auth", "admin"],
    },
  },
  {
    path: "/problematicas/:slug",
    name: "problem-public-individual",
    component: ProblemIndividualView,
    meta: {
      guard: ["auth"],
    },
  },
  {
    path: "/my-account",
    name: "mi-cuenta",
    component: MyAccountView,
    meta: {
      guard: ["auth", "admin"],
    },
  },
  {
    path: "/nuevo-problema",
    name: "newProblem",
    component: NewProblemView,
    meta: {
      guard: ["auth"],
    },
  },
  {
    path: "/departamentos",
    name: "departamentos",
    component: DepartamentsView,
    meta: {
      guard: ["admin"],
    },
  },
  {
    path: "/filtros",
    name: "filters",
    component: FiltersView,
    meta: {
      guard: ["admin"],
    },
  },
  {
    path: "/filtros/papelera",
    name: "filters-papelera",
    component: FiltersTrashView,
    meta: {
      guard: ["admin"],
    },
  },
  {
    path: "/clientes",
    name: "clientes",
    component: ClientsView,
    meta: {
      guard: ["admin"],
    },
  },
  {
    path: "/clientes/papelera",
    name: "clients-papelera",
    component: ClientsTrashView,
    meta: {
      guard: ["admin"],
    },
  },
  {
    path: "/problematicas-pendientes",
    name: "problemas-pendientes",
    component: ProblemsPendintsView,
    meta: {
      guard: ["admin"],
    },
  },
  {
    path: "/problematicas-pendientes/papelera",
    name: "problemas-pendientes-papelera",
    component: ProblemsPendintsPapeleraView,
    meta: {
      guard: ["admin"],
    },
  },
  {
    path: "/problematicas-pendientes/:slug",
    name: "problemas-pendientes-individual",
    component: ProblemsPendintsIndividualView,
    meta: {
      guard: ["admin"],
    },
  },
  {
    path: "/not-autorized",
    name: "notAuthorized",
    component: NoAutorizadoView,
    meta: {
      guard: ["all"],
    },
  },
  {
    path: "/tematicas",
    name: "tematicas",
    component: ThemesView,
    meta: {
      guard: ["admin"],
    },
  },
  {
    path: "/tematicas/papelera",
    name: "tematicasTrash",
    component: ThemesTrashView,
    meta: {
      guard: ["admin"],
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      guard: ["public"],
    },
  },
  {
    path: "/login/reset-password",
    name: "reset-password",
    component: ResetPasswordView,
    meta: {
      guard: ["public"],
    },
  },
  {
    path: "/login/reset-password/:token",
    name: "reset-password-new",
    component: CreateNewPasswordView,
    meta: {
      guard: ["public"],
    },
  },
  {
    path: "/registro",
    name: "register",
    component: CreateView,
    meta: {
      guard: ["public"],
    },
  },
  {
    path: "/registro/verify",
    name: "verify",
    component: VerifyView,
    meta: {
      guard: ["public"],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFountView,
    meta: {
      guard: ["all"],
    },
  },
  {
    path: "/not-found",
    name: "not-found-redirect",
    component: NotFountView,
    meta: {
      guard: ["all"],
    },
  },
  {
    path: "/registro/verify/:token",
    name: "verify-token",
    component: ActiveAccountView,
    meta: {
      guard: ["public"],
    },
  },
  {
    path: "/unlock-account/:token",
    name: "unlock-account",
    component: UnlockAccountView,
    meta: {
      guard: ["public"],
    },
  },
];

// Create the routes

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Obtener los datos de las cookies

function getCookieValue(cookieName) {
  var name = cookieName + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var cookieArray = decodedCookie.split(";");

  for (var i = 0; i < cookieArray.length; i++) {
    var cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return "";
}

router.beforeEach(async (to, from, next) => {
  try {
    const requiredGuards = to.meta.guard;

    if(requiredGuards.includes("all")){

      return next();

    }else if (requiredGuards.includes("admin")) {
      let user = null;

      if (!store.state.user.email) {
        try {
          await store.dispatch("getUser");
          user = store.state.user;
        } catch (err) {
          console.error(err);
          localStorage.clear();
          return next({ name: "login" });
        }
      } else {
        user = store.state.user;
      }

      // Buscando el token del usuarios y si el token es correcto entonces iniciamos sesion y el token es invalido con la llave y el encryptado el usuario no puede acceder

      let keyToken = getCookieValue("__SESSIONKEY");
      let userToken = getCookieValue("__SESSIONDATA");

      const keyArray = keyToken
        .split(",")
        .map((byte) => parseInt(byte.trim(), 10));
      const secretKeyArray = new Uint8Array(keyArray);
      const tokenAwait = await descript(userToken, secretKeyArray);

      if (tokenAwait) {
        if (to.name == "mi-cuenta") {
          return next();
        }

        if (user.email) {
          if (user.type.admin && user.type.active) {
            if (user.type.active) {
              return next();
            } else {
              return next({ name: "not-found-redirect" });
            }
          } else {
            return next({ name: "not-found-redirect" });
          }
        } else {
          localStorage.clear();
          return next({ name: "login" });
        }
      }
      localStorage.clear();
      return next({ name: "login" });
    } else if (requiredGuards.includes("auth")) {
      // Buscando el token del usuarios y si el token es correcto entonces iniciamos sesion y el token es invalido con la llave y el encryptado el usuario no puede acceder

      let keyToken = getCookieValue("__SESSIONKEY");
      let userToken = getCookieValue("__SESSIONDATA");

      const keyArray = keyToken
        .split(",")
        .map((byte) => parseInt(byte.trim(), 10));
      const secretKeyArray = new Uint8Array(keyArray);
      const tokenAwait = await descript(userToken, secretKeyArray);

      if (tokenAwait) {
        let userItem = {};

        if (!store.state.user.email) {
          try {
            await store.dispatch("getUser");
            userItem = store.state.user;
          } catch (err) {
            console.error(err);
            localStorage.clear();
            return next({ name: "login" });
          }
        } else {
          userItem = store.state.user;
        }

        if (userItem.type && userItem.type.admin && userItem.type.active) {
          return next({ name: "admin_inicio" });
        }

        return next();
      }

      localStorage.clear();

      return next({ name: "login" });
    } else if (requiredGuards.includes("public")) {
      // Buscando el token del usuarios y si el token es correcto entonces iniciamos sesion y el token es invalido con la llave y el encryptado el usuario no puede acceder

      let keyToken = getCookieValue("__SESSIONKEY");
      let userToken = getCookieValue("__SESSIONDATA");

      const keyArray = keyToken
        .split(",")
        .map((byte) => parseInt(byte.trim(), 10));
      const secretKeyArray = new Uint8Array(keyArray);
      const tokenAwait = await descript(userToken, secretKeyArray);

      if (!tokenAwait) {
        return next();
      }

      return next({ name: "inicio" });
    }

    return next();
  } catch (err) {
    // Eliminar __SESSIONDATA
    document.cookie = "__SESSIONDATA=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Eliminar __SESSIONKEY
    document.cookie = "__SESSIONKEY=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    return next({ name: "login" });
  }
});

export default router;
