<template>
  <section v-if="id" class="profile-colaboradores">
        <section class="photo" v-if="this.userData.photo" :style="{'background-image': `url(${this.userData.photo})`}">
        </section>
        <section v-else :style="style">
            <span class="photo_text" :style="styleText">{{ this.initials }}</span>
        </section>
    </section>
    <section v-else class="profile-colaboradores">
        <section class="photo" v-if="this.userData.photo" :style="{'background-image': `url(${this.userData.photo})`}">
        </section>
        <section v-else :style="style">
            <span class="photo_text" :style="styleText">{{ this.initials }}</span>
        </section>
    </section>
</template>

<script>
import { getUsers } from "@/helpers/fetchs/user";
// import colors from "@/assets/static/colors.json";
import {
  myUser,
} from "@/helpers/fetchs/user";

export default {
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      style: {
        backgroundColor: "",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
      },
      styleText: {
        color: "",
      },
      initials: "",
      userData: {},
    };
  },
  methods: {
    // randomColor() {
    //   // Array con todos los números dentro del rango
    //   let numeros = [];
    //   for (let i = 0; i <= colors.length; i++) {
    //     numeros.push(i);
    //   }

    //   // Escoge un número aleatorio del array y lo elimina
    //   let indice = Math.floor(Math.random() * numeros.length);
    //   let numeroAleatorio = numeros.splice(indice, 1)[0];

    //   // Retorna el número aleatorio seleccionado con su respectivo color
    //   let colorAleatorio = colors[numeroAleatorio];
    //   this.color = colorAleatorio;
    //   if(this.color){
    //     if(this.color.hex){
    //       this.style.backgroundColor = this.color.hex;
    //     }
    //   }

      
    // },

    determinateColorBackground() {
      // Get RGB Color

      let r;
      let g;
      let b;


      if(this.color){
        if(this.color.hex){
          r = parseInt(this.color.hex.slice(1, 3), 16);
          g = parseInt(this.color.hex.slice(3, 5), 16);
          b = parseInt(this.color.hex.slice(5, 7), 16);
        }
      }

      // Esto compara el fondo para determinar si es necesario cambiar el color del texto, para que no se oculte ante cualquier color muy oscuro o muy claro
      const brillo = (r * 299 + g * 587 + b * 114) / 1000;

      const umbral = 128;

      if (brillo >= umbral) {
        this.styleText.color = "#333333";
      } else {
        this.styleText.color = "white";
      }
    },

    name(userName) {
      const fullName = userName;
      const words = fullName.split(" "); // Divide el nombre en un array de palabras

      let iniciales = "";
      for (let i = 0; i < words.length && iniciales.length < 2; i++) {
        const word = words[i];
        if (word.length > 0) {
          iniciales += word.charAt(0).toUpperCase(); // Obtiene el primer carácter de cada palabra en mayúscula
        }
      }

      this.initials = iniciales;
    },

    async user() {


      let idUser = this.id;

      const userJSON = localStorage.getItem("__USERSITEMS");

      if(userJSON){

        const userJSONArray = JSON.parse(userJSON);

        const idExists = userJSONArray.some(function(obj) {
            return obj.id === idUser;
        });

        if(idExists){

          const userDateInfo = userJSONArray.find(function(obj) {
              return obj.id === idUser;
          });

          this.userData = userDateInfo;
          this.name(this.userData.name);
          this.style.backgroundColor = this.userData.color;

        }else{
          this.getItemUser(idUser);
        }


      }else{

          if (idUser) {
            
            this.getItemUser(idUser);

          }else{

            this.getItemUser();

          }

        }

        // this.randomColor();
        this.determinateColorBackground();

    },

    async getItemUser(idUser){

      if (idUser) {
          
            await getUsers(idUser).then((res) => { 

              this.load = true;
              return res.json().then((data) => {

                  data.data.data.id = idUser;
                  this.userData = data.data.data;
                  this.name(this.userData.name);
                  this.style.backgroundColor = this.userData.color;

                  const userJSON = localStorage.getItem("__USERSITEMS");

                  if (userJSON) {
                    const userJSONArray = JSON.parse(userJSON);
                    userJSONArray.push(this.userData);
                    localStorage.setItem("__USERSITEMS", JSON.stringify(userJSONArray));
                  } else {
                    var jsonUser = JSON.stringify([this.userData]);
                    localStorage.setItem("__USERSITEMS", jsonUser);
                  }

              });

            });

      }else{

        const Myuser = await myUser();
        this.userData = Myuser
        this.name(`${this.userData.name} ${this.userData.lastName}`);

        this.style.backgroundColor = this.userData.color;
        this.style.width = '50px';
        this.style.height = '50px';

      }

      // this.randomColor();
      this.determinateColorBackground();

    }
  },
  created() {
    this.user();
  }
};
</script>
