<template>
  <div class="tematicas">
    <MenuComponent />
    <section class="container private">
      <HeaderPrivateView />
      <section class="admin-modificacion">
        <section class="title">
          <h1>
            <span>Papelera</span>
            <br/>
            Temáticas
          </h1>
          <ButtonBackComponent nameRouter="tematicas" />
        </section>
      </section>
      <section class="container-items trash">
        <section class="all-items" v-if="load">
              <section class="items-departaments user-modify" v-for="item in items" :key="item.id">
                <section
                  class="itemColor"
                  :style="{ backgroundColor: item.color }"
                ></section>
                <span>
                  {{ item.name }}
                </span>
                <button class="restaurar" @click="SendPublic(item.id, $event.currentTarget)">
                  <i class="bi bi-arrow-clockwise"></i>
                </button>
              </section>
        </section>
        <section class="skelenton-container" v-else>
          <SkeletonModal/>
        </section>
      </section>
    </section>
  </div>
</template>

<script>
import HeaderPrivateView from "@/components/private/admin/HeaderPrivateView.vue";
import MenuComponent from "@/components/private/MenuComponent.vue";
import ButtonBackComponent from '@/components/buttons/back/ButtonBackComponent.vue';
import {
  teamsTrash,
  activeThemes

} from "@/helpers/fetchs/themes";
import { useToast } from "vue-toastification";
import SkeletonModal from "@/components/extras/SkeletonModal.vue";


const toast = useToast();

export default {
  components: {
    HeaderPrivateView,
    MenuComponent,
    SkeletonModal,
    ButtonBackComponent
  },
  data() {
    return {
      items: null,
      load: false,
    };
  },
  methods: {
    async getThemes() {

      const teamItems = await teamsTrash();
      this.load = true;
      this.items = teamItems;

    },
    async SendPublic(id, event) {

      event.disabled = true;

      let theme = {
        id: id,
      };

      const active = await activeThemes(theme);

        if (active.status == 202) {
          this.getThemes();
          toast.success("La temática se restableció con éxito.", {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
        } else {

          event.disabled = false;

          toast.error(
            "Tenemos problemas para restablecer la temática, por favor inténtelo más tarde.",
            {
              position: "bottom-right",
              timeout: 5000,
              pauseOnHover: true,
              hideProgressBar: true,
              icon: false,
              closeButton: false,
            }
          );
        }
    },
  },
  created() {
    this.getThemes();
  },
};
</script>
