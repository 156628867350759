<template>
    <div>
        <RouterLink :to="{name: nameRouter}" class="trash">
            <i class="bi bi-arrow-left"></i> 
            Regresar 
        </RouterLink>
    </div>
</template>

<script>

    export default{
        props: {
            nameRouter: String
        }
    }

</script>