<template>
    <div class="skeleton-private">
        <n-space vertical>
            <div class="dbitems">
                <n-skeleton height="50px" width="10%" />
                <n-skeleton height="50px" width="10%" />
            </div>
            <n-skeleton class="skeleton-item-private" height="60px" width="33%" />
            <n-skeleton class="skeleton-item-private" height="60px" width="66%" />
            <n-skeleton class="skeleton-item-private" height="60px" width="100%" />
        </n-space>
    </div>
</template>
