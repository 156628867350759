<template>
  <div>
    <MenuComponent />
    <section class="container private">
      <HeaderPrivateView />
      <section class="admin-modificacion">
        <section class="title">
          <h1>
            Clientes
          </h1>
          <ButtonTrashComponent nameRoute="clients-papelera" />
        </section>
        <section class="new">
          <input type="text" v-model="clients" placeholder="Nuevo" />
          <button @click="saveClient" :disabled="save">
            <i v-if="!save" class="bi bi-plus"></i>
            <n-space v-if="save">
              <n-spin size="small" stroke="$bs-success" />
            </n-space>
          </button>
        </section>
      </section>
      <section class="container-items">
        <section class="all-items" v-if="load">
            <section class="items-departaments" v-for="item in items" :key="item.id">
              <span @dblclick="editElement" @blur="resetElement">
                {{ item.name }}
              </span>
              <button
                class="save"
                @click="updateDepartamento(item.id, $event.currentTarget)"
                :disabled="true"
              >
                <i class="bi bi-check2"></i>
              </button>
              <button class="delete" @click="deleteCliente(item.id, $event.currentTarget)">
                <i class="bi bi-trash3"></i>
              </button>
            </section>
        </section>
        <section v-else>
          <SkeletonModal/>
        </section>
      </section>
    </section>
  </div>
</template>

<script>
import MenuComponent from "@/components/private/MenuComponent.vue";
import HeaderPrivateView from "@/components/private/admin/HeaderPrivateView.vue";
import ButtonTrashComponent from '@/components/buttons/trash/ButtonTrashComponent.vue';
import {
    getClients, 
    deleteClients,
    updateClients,
    saveClients
} from "@/helpers/fetchs/clients";
import SkeletonModal from "@/components/extras/SkeletonModal.vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: {
    MenuComponent,
    HeaderPrivateView,
    SkeletonModal,
    ButtonTrashComponent
  },
  data() {
    return {
      clients: "",
      save: false,
      count_nofity: 0,
      msg: "",
      type: "",
      items: null,
      contentEdit: "",
      update: false,
      load: false,
    };
  },
  methods: {
    async saveClient() {
      const input = document.querySelector(".new");
      const inputData = document.querySelector("input");
      if (!this.clients) {
        input.classList.add("null");
        return;
      }

      input.classList.remove("null");
      inputData.disabled = true;
      this.save = true;

      let client = {
        name: this.clients,
      };

      const save = await saveClients(client);

      if (save.status == 422) {
        toast.error("El cliente ya existe.", {
          position: "bottom-right",
          timeout: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });
        inputData.disabled = false;
        this.save = false;
        this.clients = null;
      } else if (save.status == 201) {
        toast.success("El Cliente se guardo con éxito.", {
          position: "bottom-right",
          timeout: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });
        inputData.disabled = false;
        this.save = false;
        this.clients = null;
        this.getClients();
      } else {
        toast.error(
          "Tenemos problemas para guardar este Cliente, por favor inténtelo más tarde.",
          {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          }
        );
        inputData.disabled = false;
        this.save = false;
      }
    },

    async getClients() {

      const clientsAll = await getClients();  
      this.items = clientsAll;

      this.load = true;

    },

    editElement(event) {
      const element = event.target;
      const containerPadre = element.parentNode;
      const botonSave = containerPadre.querySelector(".save");
      const text = element.innerHTML;
      this.contentEdit = text;

      element.contentEditable = true;
      element.classList.add("edit");
      botonSave.disabled = false;

      element.addEventListener("input", () => {
        const contenidoActual = element.innerHTML;

        if (contenidoActual.length && contenidoActual != text) {
          botonSave.disabled = false;
        } else {
          botonSave.disabled = true;
        }
      });
    },

    async deleteCliente(id, event) {
      let clients = {
        id: id,
      };

      event.disabled = true;

      const remove = await deleteClients(clients);

        if (remove.status == 202) {
          this.getClients();
          toast.success("El Cliente se elimino con éxito.", {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
        } else {
          event.disabled = false;
          toast.error(
            "Tenemos problemas para eliminar este Cliente, por favor inténtelo más tarde.",
            {
              position: "bottom-right",
              timeout: 5000,
              pauseOnHover: true,
              hideProgressBar: true,
              icon: false,
              closeButton: false,
            }
          );
        }
    },

    async updateDepartamento(id, event) {
      const textSave = event.parentNode.querySelector("span").textContent;

      let clients = {
        id: id,
        name: textSave,
      };

      event.disabled = true;

      const update = await updateClients(clients);

        if (update.status == 202) {
          
          toast.success("El Cliente se actualizo con éxito.", {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
          this.getClients();
          this.update = true;

        } else if(update.status != 422) {

          toast.error(
            "Tenemos problemas para actualizar este Cliente, por favor inténtelo más tarde.",
            {
              position: "bottom-right",
              timeout: 5000,
              pauseOnHover: true,
              hideProgressBar: true,
              icon: false,
              closeButton: false,
            }
          );

        }

    },

    resetElement(event) {
      this.getClients();

      setTimeout(() => {
        if (!this.update) {
          const element = event.target;
          const containerPadre = element.parentNode;
          const botonSave = containerPadre.querySelector(".save");
          element.contentEditable = false;
          element.innerHTML = this.contentEdit;
          element.classList.remove("edit");
          botonSave.disabled = true;
        } else {
          const element = event.target;
          const containerPadre = element.parentNode;
          const botonSave = containerPadre.querySelector(".save");
          element.contentEditable = false;
          element.classList.remove("edit");
          botonSave.disabled = true;
        }
      }, 300);
    },
  },
  mounted() {
    this.getClients();
  },
  created() {
    this.getClients();
  },
};
</script>
