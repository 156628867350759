<template>
    <div class="menu">
        <div class="menuItems"  @click="viewMenu" :class="menuActive ? 'active' : ''">
            <div class="contentHrMenu">
                <hr>
                <hr>
                <hr>
            </div>
        </div>

        <div class="content-menu" @click="closeMenu" v-if="remove" :class="itemsMenu ? 'active' : ''">
            <div class="menu-all-content" @click="closeMenuStopPropagation" :class="animationMenuView ? 'active' : ''">
                <div class="items-page">
                    <button class="closeMenu" title="Cerrar menu" @click="closeMenu">
                        <hr>
                        <hr>
                        <hr>
                    </button>
                    <div v-if="user.type.active" class="items-menu">
                        <router-link :to="{name: 'admin_inicio'}">Inicio</router-link>
                        <router-link :to="{name: 'problemas-pendientes'}">Problemáticas pendientes</router-link>
                        <router-link :to="{name: 'clientes'}">Clientes</router-link>
                        <router-link :to="{name: 'departamentos'}">Departamentos</router-link>
                        <router-link :to="{name: 'tematicas'}">Temáticas</router-link>
                        <router-link :to="{name: 'filters'}">Filtros</router-link>
                    </div>
                    <div v-else class="items-menu">
                        <div class="item-menu">
                            Problemáticas
                            <ul>
                                <li>
                                    <router-link to="/">Todas</router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="item-menu">
                            Nueva problemática
                            <ul>
                                <li>
                                    <router-link :to="{name: 'newProblem'}">Añadir</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="items-user">
                    <div class="user-actions">
                        <div class="profile">
                            <img v-if="user.photo" :src="user.photo" alt="">
                            <div v-else :style="style">
                                <span class="profileIcons" :style="styleText" >{{ initials }}</span>
                            </div>
                        </div>
                        <div class="active-admin" v-if="user.type.admin">
                            <n-popover trigger="hover">
                                <template #trigger>
                                    <n-switch :disabled="updatedUser" v-model:value="user.type.active" @click="changeAdministrador" />
                                </template>
                                <span>{{ user.type.active? 'Desactivar administrador' : 'Activar administrador' }}</span>
                            </n-popover>
                        </div>
                    </div>
                    <router-link :to="{name: 'mi-cuenta'}" class="user-items-link" @click="closeMenu">
                        <div class="edit-user-icon">
                            <h2>{{ user.name }} {{ user.lastName  }}</h2>
                        </div>
                        <span>{{ user.email }}</span>
                    </router-link>

                    <button @click="logout" class="close-sesion" :disabled="closeAccound === 'Cerrando sesión'">{{ closeAccound }}</button>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {
        logout,
        updateAdmin
    } from '@/helpers/fetchs/user';

    // import colors from '@/assets/static/colors.json';


    export default{
        data(){
            return{
                menuActive: false,
                itemsMenu: false,
                animationMenuView: false,
                remove: false,
                style: {
                    backgroundColor: 'red',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                color: "",
                styleText: {
                    color: ""
                }, 
                name: {
                    firstName:  localStorage.getItem('name') ? localStorage.getItem('name').charAt()[0] : null,
                    lastName:  localStorage.getItem('last_name') ? localStorage.getItem('last_name').charAt()[0] : null,
                },
                closeAccound: "Cerrar sesión",
                updatedUser: false,
                initials: '',
            }
        },
        methods: {

            clearCookies(){
                var cookies = document.cookie.split(";");

                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i];
                    var eqPos = cookie.indexOf("=");
                    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
                }
            },

            viewMenu(){

                !this.menuActive ? this.menuActive = true : this.menuActive = false;

                if(!this.itemsMenu){

                    this.remove = true;

                    setTimeout(()=>{
                        this.itemsMenu = true;
                    }, 600)

                    setTimeout(()=>{
                        this.animationMenuView = true;
                    }, 1000)

                }else{

                    this.itemsMenu = false;
                }

                const user = this.$store.state.user;
                this.style.backgroundColor = user.color;
                this.color = user.color;
                this.determinateColorBackground();
                this.nameInitial();
            },

            closeMenuStopPropagation(event){

                event.stopPropagation();

            },

            closeMenu(){

                this.animationMenuView = false;
                setTimeout(()=>{
                        this.itemsMenu = false;
                }, 1100)
                setTimeout(()=>{
                    this.menuActive = false;
                }, 1500)
                setTimeout(()=>{
                    this.remove = false;
                }, 1900)

            },

            async logout(){

                this.closeAccound = "Cerrando sesión";

                await logout().then(res=>{

                    if(res.status == 202){
                        localStorage.clear();
                        sessionStorage.clear();
                        this.clearCookies();
                        this.$router.push({name: 'login'})
                        return;
                    }

                    localStorage.clear();
                    this.$router.push({name: 'login'})
                    return;

                });

            },

            determinateColorBackground(){

                // Get RGB Color
                const r = parseInt(this.style.backgroundColor.slice(1, 3), 16);
                const g = parseInt(this.style.backgroundColor.slice(3, 5), 16);
                const b = parseInt(this.style.backgroundColor.slice(5, 7), 16);


                // Esto compara el fondo para determinar si es necesario cambiar el color del texto, para que no se oculte ante cualquier color muy oscuro o muy claro
                const brillo = (r * 299 + g * 587 + b * 114) /  1000;

                const umbral = 128;

                if(brillo >= umbral){
                    this.styleText.color = '#333333'
                }else{
                    this.styleText.color = '#333333'
                }

            },

            async changeAdministrador(){
                const admin = this.$store.state.user.type.active;
                this.updatedUser = true;

                let data = {
                    active: admin ? 1 : 0,
                };

                await updateAdmin(data);

                this.$store.dispatch('getUser');

                this.updatedUser = false;

                if(data.active){
                    this.$router.push({name: 'admin_inicio'})
                }else{
                    this.$router.push({name: 'inicio'})
                }

            },

            nameInitial() {

                const user = this.$store.state.user;
                const fullName = user.name + " " + user.last_name;
                const words = fullName.split(" "); // Divide el nombre en un array de palabras

                let iniciales = "";
                for (let i = 0; i < words.length && iniciales.length < 2; i++) {
                    const word = words[i];
                    if (word.length > 0) {
                    iniciales += word.charAt(0).toUpperCase(); // Obtiene el primer carácter de cada palabra en mayúscula
                    }
                }

                this.initials = iniciales;

            },

        },
        computed: {

            user() {
                const user = this.$store.state.user;
                return user;
            }

        },
        created(){

            this.$store.dispatch('getUser');
            // this.randomColor();

        }

    }

</script>
