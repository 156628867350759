<template>
    <div class="messages">
        <section class="allMessages scroll">

            <section class="message" v-for="message in messages" :key="message.id"
                :class="this.$store.state.user.id == message.user.id ? 'staff' : ''">
                <section class="icon-user-message">
                    <UserIconsView :id="message.user.id" />
                </section>
                <section class="content-message">
                    <section class="information">
                        <strong class="name-user-message">
                            {{ message.user.name }}
                        </strong>
                        <p class="text-message">
                            {{ message.message }}
                        </p>
                    </section>
                    <section class="date">
                        <span class="date-message">
                            {{ message.date }}
                        </span>
                    </section>
                </section>
            </section>

            <section class="userWrite">
                <section class="writen" v-for="writen in writeUser" :key="writen.id">
                    <section class="content-writen">
                        <p class="text-writen">
                            {{ writen.name }} esta escribiendo...
                        </p>
                    </section>
                </section>



            </section>

        </section>
        <section class="sendMessage">
            <section class="myuser">
                <UserIconsView />
            </section>
            <form @submit.prevent="sendMessage">
                <input type="text" placeholder="¿Qué estás pensando?" v-model="newMessage" @input="writenChat"
                    @blur="dejoEscribir">
                <button type="submit" class="send_move message-send">
                    <svg width="20" viewBox="0 0 45 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.9 40.17L43.44 20.59L24.9 1" stroke="white" stroke-miterlimit="10" />
                        <path d="M43.45 20.59H0" stroke="white" stroke-miterlimit="10" />
                    </svg>

                </button>
            </form>
        </section>
    </div>
</template>

<script>
import UserIconsView from "../../admin/UserIconsView.vue";
import { saveMessage, getMessage, socketMessage } from "@/helpers/fetchs/messages";
import Pusher from 'pusher-js';

// Required

const sendMessage = require("../../../../assets/sound/enviado.wav");
const recibidoMessage = require("../../../../assets/sound/recibido.wav");

export default {
    components: {
        UserIconsView
    },
    data() {
        return {
            paramsUrl: this.$route.params.slug,
            myuser: {},
            messages: [],
            newMessage: '',
            writeUser: [],

        }
    },
    methods: {
        async getAllMessage() {
            const messageAll = await getMessage(this.$route.params.slug);
            this.messages = messageAll;
        },

        async sendMessage() {

            if (this.newMessage) {
                const messageText = this.newMessage;

                this.newMessage = '';
                const messageJson = {
                    id: this.messages.length,
                    date: this.newDateTime(),
                    message: messageText,
                    user: {
                        id: this.userState().id,
                        name: this.userState().name,
                    }
                }


                this.messages.unshift(messageJson);

                this.scrollAnimation();

                const audio = new Audio(sendMessage);
                audio.volume = 0.1;
                audio.play();

                // Aqui ejecutamos el mensajes para los usuarios
                await socketMessage(messageJson, this.paramsUrl);


                if (messageText.length) {

                    const data = {
                        message: messageText
                    }

                    await saveMessage(this.paramsUrl, data);
                }
            }

        },

        scrollAnimation() {
            setTimeout(() => {
                const chatContent = document.querySelector('.allMessages');
                chatContent.scrollTo({
                    top: chatContent.scrollHeight,
                    behavior: 'smooth'
                })
            }, 100)
        },

        newDateTime() {
            // Obtiene la fecha y hora actuales
            const fechaActual = new Date();

            // Obtiene el día del mes y lo formatea con dos dígitos
            const dia = ('0' + fechaActual.getDate()).slice(-2);

            // Obtiene el mes y lo formatea con el nombre completo en español
            const meses = [
                'enero',
                'febrero',
                'marzo',
                'abril',
                'mayo',
                'junio',
                'julio',
                'agosto',
                'septiembre',
                'octubre',
                'noviembre',
                'diciembre'
            ];

            const mes = meses[fechaActual.getMonth()];

            // Obtiene la hora y los minutos
            let horas = fechaActual.getHours();
            let minutos = fechaActual.getMinutes();

            // Ajusta el formato de las horas (formato de 12 horas)
            const periodo = horas >= 12 ? 'p.m.' : 'a.m.';
            horas = horas % 12 || 12;

            // Formatea los minutos con dos dígitos
            minutos = ('0' + minutos).slice(-2);

            // Genera la fecha y hora formateadas
            const fechaFormateada = `${dia} ${mes} ${horas}:${minutos} ${periodo}`;

            return fechaFormateada;

        },

        webSocket() {

            // Enable pusher logging - don't include this in production
            // Pusher.logToConsole = true;

            const pusher = new Pusher('6176518ed5065839e14f', {
                cluster: 'mt1'
            });

            const channel = pusher.subscribe('message_channel');
            const self = this;

            channel.bind('event_message', function (data) {

                const messageData = data.message;
                if (messageData) {
                    const userMessage = messageData.user;
                    const userID = userMessage.id;
                    const userActual = self.userState().id;
                    const idMessage = data.slug;


                    if ((userID != userActual) && (idMessage == self.paramsUrl)) {

                        const messageData = data;
                        const messageJson = {
                            id: self.messages.length,
                            date: messageData.message.date,
                            message: messageData.message.message,
                            user: {
                                id: messageData.message.user.id,
                                name: messageData.message.user.name,
                            }
                        }

                        self.messages.unshift(messageJson);
                        self.scrollAnimation();

                        const audio = new Audio(recibidoMessage);
                        audio.play();

                    }
                }

            });

        },

        userState() {

            const userData = this.$store.state;

            if (userData) {

                const data = userData.user;
                return {
                    id: data.id,
                    name: data.name + " " + data.lastName
                }

            }
        },

    },
    mounted() {
        this.webSocket()
    },
    created() {
        this.getAllMessage();
    }


}

</script>