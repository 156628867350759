<template>
    <div>
        <section class="header-admin">
            <HeaderAuth/>
        </section>
        <section class="not-found">
            <h1>404</h1>
        </section>
    </div>
</template>

<script>

    import HeaderAuth from '@/components/public/HeaderAuth.vue';

    export default{
        components: {
            HeaderAuth
        }
    }

</script>