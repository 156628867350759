import { ref } from "vue";
import * as Msal from "@azure/msal-browser";

class AzureService {

    msalConfig = {}
    accessToken = ''

    constructor(){
        this.msalConfig =  ref({
            auth:{
                clientId: 'a48d7a91-1881-4928-a3bf-661298103a62',
                authority: 'https://login.microsoftonline.com/6220c548-8b15-4258-ba64-21d1abe8e727'
            },
            cache: {
                cacheLocation: 'localStorage'
            },
        })

        this.accessToken = ref(''),
        this.token = '';
    }

    async login() {
        const msalInstance = new Msal.PublicClientApplication(this.msalConfig.value)
    
        const loginRequest = {
          scopes: ["user.read", "User.ReadBasic.All"],
        }
    
        const response = await msalInstance.loginPopup(loginRequest)
    
        if (response.account) {
          const tokenRequest = {
            scopes: ["user.read", "User.ReadBasic.All"],
            account: response.account,
          }
    
          const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest)

          return tokenResponse.accessToken
        } else {
          throw new Error("No account found")
        }
    }

    async getUser() {
      
        const login = await this.login();
        await this.login();
        const url = "https://graph.microsoft.com/v1.0/me"
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${login}`,
          },
        });

        return await response.json()
    }

    async getUserPhoto() {

        const login = await this.login();

        const url = "https://graph.microsoft.com/v1.0/me/photo/$value"
        const photo = await fetch(url, {
          headers: {
            Authorization: `Bearer ${login}`,
          },
        });

        const blob = await photo.blob();
        const url_photo =  URL.createObjectURL(blob)

        return url_photo;

    }

    getMsalConfig(){
        return this.msalConfig;
    }



}

export default AzureService;