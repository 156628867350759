let errors = false;

const validation = (input, type = 'grupal') => {

    if(type == 'individual'){

        const inputPasswordClassName = input.className;
        const word = 'not-validation';
        const searchWord = inputPasswordClassName.indexOf(word);
        const input_type = input.type;
        const parent = input.parentNode.parentNode;
        const validate = parent.querySelector('.error');
        const pattern = /^[a-zA-Z0-9._%+-]+@garnierbbdo\.com$/;
        const password_input = document.querySelector('input[type="password"]');
        const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;

        if(!input.value){
            if(!input.value){
                input.classList.add('error-input')
                errors = true;
            }else{
                validate.textContent = '';
                validate.classList.remove('active');
                input.classList.remove('error-input')
                errors = false;
            }
        }else if(input_type == 'email'){
            if(!pattern.test(input.value)){
                validate.textContent = 'Este correo electrónico no es valido'
                validate.classList.add('active');
                errors = true;
            }else{
                validate.textContent = '';
                validate.classList.remove('active');
                input.classList.remove('error-input')
                errors = false;
            }
        }else if(input.name == 'repeat'){
            if(input.value != password_input.value){
                validate.textContent = 'Las contraseñas no coinciden'
                validate.classList.add('active');
                errors = true;
            }else{
                validate.textContent = '';
                validate.classList.remove('active');
                input.classList.remove('error-input')
                errors = false;
            }
        }else if(input_type == 'password' && searchWord <= -1){
            if((input.value.length < 12 || !regex.test(input.value))){
                    validate.innerHTML = `<ul>
                                                <li>Necesita mas de 12 caracteres.</li>
                                                <li>Debe tener letras mayusculas.</li>
                                                <li>Debe tener letras minisculas.</li>
                                                <li>Debe tener numeros.</li>
                                                <li>Debe tener caracteres especiales.</li>
                                        </ul>`;
                    validate.classList.add('active');
                    errors = true;
            }else{
                validate.textContent = ''
                validate.classList.remove('active');
                input.classList.remove('error-input')
                errors = false;
            }

        }else{
            validate.textContent = ''
            validate.classList.remove('active');
            input.classList.remove('error-input')
            errors = false;
        }
    }

    return errors;

}

export default validation;