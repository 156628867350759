<template>
    <div>
        <MenuComponent/>
        <section class="header-admin">
            <HeaderAuth/>
        </section>
        <section class="container private">
            <section class="authorization">
                <section class="container-not-autorization">
                    <h3>Administrador inactivo</h3>
                    <p>Para acceder al administrador abra el menu y active el swith de administrador</p>
                </section>
            </section>
        </section>
    </div>
</template>

<script>

    import MenuComponent from '@/components/private/MenuComponent.vue';
    import HeaderAuth from '@/components/public/HeaderAuth.vue';

    export default{
        components: {
            MenuComponent,
            HeaderAuth
        },
    }

</script>
