<template>
    <div class="container-problems-publics">

        <section v-if="load">
            <h1>{{ problemContent.name }} <span>+</span> {{ problemContent.client }} </h1>
            <h2>Data e información de caso</h2>
            <p>{{ problemContent.data }}</p>

            <section class="problems-container">
                <section>
                    <h2>Links</h2>
                    <ul>
                        <li v-for="item in problemContent.links" :key="item">
                            <a :href="item" target="_black">{{ item }}</a>
                        </li>
                    </ul>
                </section>
                <section>
                    <h2>Miro</h2>
                    <a :href="problemContent.miro" target="_black">{{ problemContent.miro }}</a>
                </section>
                <section>
                    <h2>Colaboradores</h2>
                    <section class="collaborators" v-if="problemContent.collaborators">

                        <section v-for="item in problemContent.collaborators" :key="item.id" class="user-modify">
                            <span @dblclick="editElement" @blur="resetElement">
                                <UserIconsView :id="item.id"/>
                            </span>
                        </section>

                    </section>
                    <p class="errorCollaboradores" v-else>Sin colaboradores</p>
                </section>
            </section>
        </section>
        <section v-else>
            <SkeletonProblemsIndividual/>
        </section>
    </div>
</template>

<script>

    import { problemsApprovedIndividual } from "@/helpers/fetchs/publicProblems";
    import SkeletonProblemsIndividual from "@/components/extras/SkeletonProblemsIndividual.vue";
    import UserIconsView from "../../admin/UserIconsView.vue";

    export default{
        components: {
            SkeletonProblemsIndividual,
            UserIconsView
        },
        props: {
            updateCollaborators: Boolean,
        },
        data(){
            return{
                paramsUrl: this.$route.params.slug,
                problemContent: {},
                load: false,
            }
        },
        methods: {
            async getProblem(){

                const problem = await problemsApprovedIndividual(this.paramsUrl); 
                this.problemContent = problem;
                this.load = true;

            }
        },
        watch: {
            updateCollaborators(){
                this.getProblem();
            }
        },
        created() {
            this.getProblem();
        }

    }

</script>
