<template>
    <div>
        <button class="login_microsofgt helvicaFont" @click="loginAzure">
            Ingresá con <img src="@/assets/img/microsoft.svg" alt="" />
        </button>
        <div v-if="viewModal" class="modal" :class="openModal ? 'active' : ''">
            <section class="content_modal">
                <section class="cambiando-user">
                    <n-spin>
                    <template #description>Esperando datos del usuario...</template>
                    </n-spin>
                </section>
            </section>
        </div>
    </div>
</template>

<script>

import {
  microsoftAccount,
} from "@/helpers/fetchs/user";

import AzureService from "@/helpers/Azureservice";
import convertImg from "@/helpers/convertImg";
import { encript, generateAESKey } from "@/helpers/secret/encrypt_decrypt";
import { useToast } from 'vue-toastification';

const toast = useToast();

import { PublicClientApplication } from "@azure/msal-browser";

export default{



    data() {
        return{
            viewModal: false,
            openModal: false,
            text: 'Esperando datos del usuario...',
        }
    },
    methods: {
        async loginAzure() {

            this.viewModal = true;
            setTimeout(() => {
                this.openModal = true;
            }, 10);
            
            const azureServices = new AzureService();

            await this.$msalInstance
                .loginPopup({})
                .then(async () => {
                const userPhoto = await azureServices.getUserPhoto();
                const { username, name } = this.$msalInstance.getAllAccounts()[0];

                if (username && name) {
                    const userArray = name.split(" ");
                    const firstName = userArray[0];
                    const last_name = userArray[1];
                    this.$emitter.emit("login", this.account);

                    await convertImg.imgUser(userPhoto).then(async (res) => {
                    const form = new FormData();
                    form.append("email", username);
                    form.append("name", firstName);
                    form.append("last_name", last_name);
                    form.append("photo", res);

                    // Login user

                    this.text = "Verificando datos del usuario..."

                    await microsoftAccount(form)
                        .then((res) => {
                        if (res.status == 201 || res.status == 200) {
                            return res.json().then( async (data) => {
                            const { token } = data.data.data;

                            const newkey = await generateAESKey();

                            // Save key
                            const expires = new Date();
                            expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000); // 1 día en milisegundos

                            // Llave de la sesion
                            document.cookie = `__SESSIONKEY=${newkey};expires=${expires.toUTCString()};path=/`;

                            // Datos del usuario:


                            // Convertir el objeto en una cadena JSON

                            const jsonEncript = await encript(token, newkey);
                            document.cookie = `__SESSIONDATA=${jsonEncript};expires=${expires.toUTCString()};path=/`;

                            this.$router.push({
                                name: "inicio"
                            });

                            });
                        } else if (res.status == 422) {
                            this.openModal = false;
                            setTimeout(() => {
                                this.viewModal = false;
                                this.text = 'Esperando datos del usuario...';
                            }, 400);
                            toast.error('Este usuario no está registrado con Microsoft.',{ 
                                position: 'bottom-right',
                                timeout: 5000,
                                pauseOnHover: true,
                                hideProgressBar: true,
                                icon: false,
                                closeButton: false
                                });
                        } else {
                            this.openModal = false;
                            setTimeout(() => {
                                this.viewModal = false;
                                this.text = 'Esperando datos del usuario...';
                            }, 400);
                            toast.error('Opps, no se puedo iniciar con su cuenta, por favor inténtelo dentro de unos minutos.',{ 
                                position: 'bottom-right',
                                timeout: 5000,
                                pauseOnHover: true,
                                hideProgressBar: true,
                                icon: false,
                                closeButton: false
                            });
                        }
                        })
                        .catch(() => {

                            this.openModal = false;
                            setTimeout(() => {
                                this.viewModal = false;
                                this.text = 'Esperando datos del usuario...';
                            }, 400);

                            toast.error("Ocurrió un problema al iniciar con Microsoft, asegúrese de tener habilita la ventana emergente o verifique que no tenga otra venta de Microsoft abierta.",{ 
                                    position: 'bottom-right',
                                    timeout: 5000,
                                    pauseOnHover: true,
                                    hideProgressBar: true,
                                    icon: false,
                                    closeButton: false
                                    });
                            
                            });
                    });
                }
                })
                .catch(() => {
                    this.openModal = false;
                    setTimeout(() => {
                        this.viewModal = false;
                        this.text = 'Esperando datos del usuario...';
                    }, 400);
                toast.error("Ocurrió un problema al iniciar con Microsoft, asegúrese de tener habilita la ventana emergente o verifique que no tenga otra venta de Microsoft abierta.",{ 
                        position: 'bottom-right',
                        timeout: 5000,
                        pauseOnHover: true,
                        hideProgressBar: true,
                        icon: false,
                        closeButton: false
                    });
                });
            },
    },
    async created() {
        const azureServices = new AzureService();
        this.$msalInstance = new PublicClientApplication(
        azureServices.getMsalConfig().value
        );
    },

}

</script>