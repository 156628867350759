<template>
    <div class="botonsActionsFilters">
        <form @submit.prevent="sendFilter" class="execute-group" :class="{'active-exexute': moveFilterInput, 'remove': executeFilterRemove}">
            <input type="text" placeholder="Inserte el token de filtros" v-model="textFilters">
            <button class="">
                <i class="bi bi-gear"></i>
            </button>
            <button class="close-input" type="button" @click="closeInputFilter">
                <i class="bi bi-x-lg"></i>
            </button>

        </form>
        <div class="botonsGroups" :class="{'active-exexute': executeFilterClass, 'remove': executeFilterRemove}">
            <button class="actions" @click="copyFilters" :disabled="copiar">
                {{ copyText }}
            </button>
            <button class="actions execute" @click="executeFilter">
                Ejecutar filtro
            </button>
        </div>
    </div>
</template>

<script>

    import { encript, descript } from '@/helpers/secret/encrypt_decrypt';
    import { useToast } from "vue-toastification";
    const toast = useToast();
    export default{
        data(){
            return{
                copiar: false,
                copyText: "Copiar filtros",
                executeFilterClass: false,
                executeFilterRemove: false,
                moveFilterInput: false,
                textFilters: "",
                keyGarnier: '218,103,229,65,99,155,68,42,194,42,219,147,230,52,97,192', 
            }
        },
        methods: {
            async copyFilters(){  
                this.copiar = true;
                this.copyText = "Copiando filtros";

                const copyJson = {};

                if(localStorage.getItem('client')){
                    copyJson.client = localStorage.getItem('client');
                }

                if(localStorage.getItem('order')){
                    copyJson.order = localStorage.getItem('order');
                }

                if(localStorage.getItem('theme')){
                    copyJson.theme = localStorage.getItem('theme');
                    copyJson.themeColor = localStorage.getItem('theme-color');
                }

                if(localStorage.getItem('search')){
                    copyJson.search = localStorage.getItem('search');
                }

                const copyJsonString = JSON.stringify(copyJson);

                const jsonEncript = await encript(copyJsonString, this.keyView());

                navigator.clipboard.writeText(jsonEncript)
                .then(() => {

                    this.copyText = "Filtros copiados";

                    setTimeout(()=>{
                        this.copiar = false;
                        this.copyText = "Copiar filtros";
                    }, 2000)
                })
                .catch(() => {
                    this.copyText = "Error al copiar";

                    setTimeout(()=>{
                        this.copiar = false;
                        this.copyText = "Copiar filtros";
                    }, 2000)
                });


            },

            executeFilter(){
                this.executeFilterClass =  true;
                this.moveFilterInput =  true;

                if(this.executeFilter){
                    setTimeout(()=>{
                        this.executeFilterRemove = true;
                    }, 200)
                }else{
                    this.executeFilterRemove = false;
                }

            },

            closeInputFilter(){
                this.executeFilterRemove = false;
                this.moveFilterInput = false;

                setTimeout(()=>{
                    this.executeFilterClass = false;
                    this.textFilters = '';
                }, 20)
            },

            handleKeyDown(key) {
                if (key === 27) {
                    this.closeInputFilter();
                }
            },

            keyView(){
                const keyArray = this.keyGarnier.split(",").map((byte) => parseInt(byte.trim(), 10));
                const secretKey = new Uint8Array(keyArray);
                return secretKey;
            },

            async sendFilter(){

                if(this.textFilters){
                    const descriptItem = await descript(this.textFilters, this.keyView())
                    if(descriptItem){

                        const jsonFilters = JSON.parse(descriptItem);
                        const exist = Object.keys(jsonFilters);

                        if(exist.length > 0){

                            if(jsonFilters.search){
                                localStorage.setItem('search', jsonFilters.search);
                            }

                            if(jsonFilters.client){
                                localStorage.setItem('client',  parseInt(jsonFilters.client));
                            }

                            if(jsonFilters.theme){
                                localStorage.setItem('theme', jsonFilters.theme);
                                if(jsonFilters.themeColor){
                                    localStorage.setItem('theme-color', jsonFilters.themeColor);
                                }
                            }

                            if(jsonFilters.order){
                                localStorage.setItem('order', jsonFilters.order);
                            }

                            this.$emit('updateFilters');

                            this.closeInputFilter();

                        }else{
                            toast.error(
                                `Este token no contiene filtros`,
                                {
                                position: "bottom-right",
                                timeout: 5000,
                                pauseOnHover: true,
                                hideProgressBar: true,
                                icon: false,
                                closeButton: false,
                                }
                            );
                        }

                    }else{
                        toast.error(
                            `Este token no es valido`,
                            {
                            position: "bottom-right",
                            timeout: 5000,
                            pauseOnHover: true,
                            hideProgressBar: true,
                            icon: false,
                            closeButton: false,
                            }
                        );
                    }
                }

            } 
        },
        mounted(){
            window.addEventListener('keydown', (event)=>{
                this.handleKeyDown(event.keyCode);
            })
        }, 

    }

</script>
