<template>
    <div>
        <MenuComponent />
        <section class="container private">
            <HeaderPrivateView/>
            <section class="admin-modificacion">
                <section class="title">
                    <h1>
                        Modificaciones <br/> en filtros
                    </h1>
                    <ButtonTrashComponent nameRoute="filters-papelera" />
                </section>
            </section>
            <section class="container-items">
                <section class="all-items" v-if="load">
                    <section
                        class="user-modify"
                        v-for="item in items"
                        :key="item.id"
                    >
                        <section class="items-departaments">
                            <span @dblclick="editElement" @blur="resetElement">
                            {{ item.name }}
                            </span>
                            <button
                            class="save"
                            @click="modify(item.id, $event.currentTarget)"
                            :disabled="true"
                            >
                            <i class="bi bi-check2"></i>
                            </button>
                            <button class="delete" @click="deleteFilters(item.id, $event.currentTarget)">
                            <i class="bi bi-trash3"></i>
                            </button>
                        </section>
                    </section>
                </section>
                <section class="skelenton-container" v-else>
                    <SkeletonModal/>
                </section>
            </section>
        </section>
    </div>
</template>

<script>
    import MenuComponent from "@/components/private/MenuComponent.vue";
    import HeaderPrivateView from "@/components/private/admin/HeaderPrivateView.vue";
    import SkeletonModal from "@/components/extras/SkeletonModal.vue";
    import ButtonTrashComponent from '@/components/buttons/trash/ButtonTrashComponent.vue';


    import { getFilters, updateFilter } from '@/helpers/fetchs/filters';

    import { useToast } from "vue-toastification";
    const toast = useToast();

    export default{

        components: {
            MenuComponent,
            HeaderPrivateView,
            SkeletonModal,
            ButtonTrashComponent
        }, 
        data(){
            return{
                load: false,
                items: {},
                contentEdit: "",
                update: false,
            }
        },
        methods: {

            async filters(){

                const items = await getFilters();
                this.items = items;

                this.load = true;

            },

            editElement(event) {
                const element = event.target;
                const containerPadre = element.parentNode;
                const botonSave = containerPadre.querySelector(".save");
                const text = element.innerHTML;
                this.contentEdit = text;

                element.contentEditable = true;
                element.classList.add("edit");
                botonSave.disabled = false;

                element.addEventListener("input", () => {
                    const contenidoActual = element.innerHTML;

                    if (contenidoActual.length && contenidoActual != text) {
                    botonSave.disabled = false;
                    } else {
                    botonSave.disabled = true;
                    }
                });
            },

            resetElement(event) {

                this.filters();

                setTimeout(() => {
                    if (!this.update) {
                    const element = event.target;
                    const containerPadre = element.parentNode;
                    const botonSave = containerPadre.querySelector(".save");
                    element.contentEditable = false;
                    element.innerHTML = this.contentEdit;
                    element.classList.remove("edit");
                    botonSave.disabled = true;
                    } else {
                    const element = event.target;
                    const containerPadre = element.parentNode;
                    const botonSave = containerPadre.querySelector(".save");
                    element.contentEditable = false;
                    element.classList.remove("edit");
                    botonSave.disabled = true;
                    }
                }, 300);
            },

            modify(id, event) {

                const textSave = event.parentNode.querySelector("span").textContent;

                event.disabled = true;
                

                let filter = {
                    id: id,
                    name: textSave,
                };

                this.updateFilter(filter, true);

                
            },

            async deleteFilters(id, event) {

                event.disabled = true;

                let deleteFilter = {
                    id: id,
                    status: 0,
                };

               this.updateFilter(deleteFilter, false);

            },

            async updateFilter(data, update){

                const newFilter = await updateFilter(data);

                if (newFilter.status == 202) {

                    if(update){
                        toast.success(`El filtro se actualizo con éxito.`, {
                            position: "bottom-right",
                            timeout: 5000,
                            pauseOnHover: true,
                            hideProgressBar: true,
                            icon: false,
                            closeButton: false,
                        });
                    }else{
                        toast.success(`El filtro se elimino con éxito.`, {
                            position: "bottom-right",
                            timeout: 5000,
                            pauseOnHover: true,
                            hideProgressBar: true,
                            icon: false,
                            closeButton: false,
                        });
                    }

                    this.filters();
                    this.update = true;

                } else {
                    if(update){
                        toast.error(
                            `Tenemos problemas para actualizar este filtro, por favor inténtelo más tarde.`,
                            {
                            position: "bottom-right",
                            timeout: 5000,
                            pauseOnHover: true,
                            hideProgressBar: true,
                            icon: false,
                            closeButton: false,
                            }
                        );
                    }else{
                        toast.error(
                            `Tenemos problemas eliminar este filtro, por favor inténtelo más tarde.`,
                            {
                            position: "bottom-right",
                            timeout: 5000,
                            pauseOnHover: true,
                            hideProgressBar: true,
                            icon: false,
                            closeButton: false,
                            }
                        );
                    }
                }   
            }


        },
        created(){
            this.filters();
        }

    }

</script>