<template>
  <div class="login center h-v-100">
    <HeaderAuth />

    <section class="container form">
      <h1>
        Iniciá sesión
      </h1>
      <form @submit.prevent="login()">
        <section class="individual">
          <label for="email">
            <section class="relative_inputs helvicaFont">
              <input
                type="email"
                id="email"
                v-model="value.email"
                autocomplete="username"
                placeholder="Correo electrónico"
                autofocus
              />
            </section>
            <section class="error email_error"/>
          </label>
        </section>


        <section class="individual">
          <label for="password">
            <section class="relative_inputs helvicaFont">
              <input
                type="password"
                class="not-validation"
                id="password"
                v-model="value.password"
                autocomplete="password"
                placeholder="Contraseña"
              />
              <button
                type="submit"
                :disabled="botton.disabled"
                class="send_move"
              >
                <svg v-if="!loading" width="22" viewBox="0 0 45 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.9 40.17L43.44 20.59L24.9 1" stroke="white" stroke-miterlimit="10"/>
                    <path d="M43.45 20.59H0" stroke="white" stroke-miterlimit="10"/>
                </svg>
                <div class="loadingItem" v-else></div>
              </button>
            </section>
            <section class="error email_error"/>
          </label>
        </section>
        <router-link
          :to="{ name: 'reset-password' }"
          class="reset_password_link"
          >¿Olvidaste tu contraseña?</router-link
        >
        <p
          v-if="userIncorrect"
          class="error email_error"
          :class="userIncorrect ? 'active' : ''"
        >
          {{ userIncorrect }}
        </p>
      </form>
    </section>

    <section class="registrarse helvicaFont">
      <ButtonLoginView/>
      -
      <RouterLink :to="{ name: 'register' }"> Registrarse aquí </RouterLink>
    </section>

  </div>
</template>

<script>
import validation from "@/helpers/validation";

import {
  login
} from "@/helpers/fetchs/user";

import HeaderAuth from "@/components/public/HeaderAuth.vue";

// Azure auth

import ButtonLoginView from '@/components/loginMicrosoft/ButtonLoginView';

// Encryptation
import { encript, generateAESKey } from "@/helpers/secret/encrypt_decrypt";

// Auth with Firebase

export default {
  components: {
    HeaderAuth,
    ButtonLoginView
  },
  data() {
    return {
      value: {
        email: "",
        password: "",
      },
      validation_all: {
        inputs: 0,
        email: "",
      },
      botton: {
        registro: "",
        disabled: false,
      },
      send: {
        registro: "",
      },
      account: "",
      userIncorrect: "",
      loading: false,
    };
  },

  methods: {

    // Validation all

    validation() {
      this.validation_all.inputs = 0;
      const elements_child = document.querySelectorAll("input");
      const type = "individual";
      elements_child.forEach((e) => {
        if (!validation(e, type)) {
          this.validation_all.inputs += 1;
        }
      });
    },

    async login() {

      this.validation();
      this.userIncorrect = "";
      const lengt_inputs = document.querySelectorAll("input").length;

      if (lengt_inputs == this.validation_all.inputs) {
        const elements_child = document.querySelectorAll("input");
        elements_child.forEach((e) => {
          e.disabled = true;
        });

        this.botton.disabled = true;
        this.loading = true;

        const form = new FormData();
        form.append("email", this.value.email);
        form.append("password", this.value.password);

        //Verify user
        await login(form).then((res) => {

          this.loading = false;

          // Valida que el usuario esta registrado con microsoft
          if (res.status == 403) {
            this.userIncorrect = "Este usuario esta registrado con Microsoft.";
            elements_child.forEach((e) => {
              e.disabled = false;
            });

            this.botton.disabled = false;
          } else if (res.status == 401) {
            // Significa que el usuario o contraseña son invalidos
            this.userIncorrect = "El usuario o la contraseña son incorrectos.";
            elements_child.forEach((e) => {
              e.disabled = false;
            });
            this.value.password = ''

            this.botton.disabled = false;
          } else if (res.status == 200) {

            // Aqui el usuario se loguea con exito

            return res.json().then( async (data) => {
              const dataUser = data.data.data;
              const newkey = await generateAESKey();

              // Save key
              const expires = new Date();
              expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000); // 1 día en milisegundos

              // Llave de la sesion
              document.cookie = `__SESSIONKEY=${newkey};expires=${expires.toUTCString()};path=/`;

              // Datos del usuario:

              // Convertir el objeto en una cadena JSON

              const jsonEncript = await encript(dataUser.token, newkey);
              document.cookie = `__SESSIONDATA=${jsonEncript};expires=${expires.toUTCString()};path=/`;

              this.$router.push({
                        name: "inicio"
                      });
                      
            });
          } else if (res.status == 400) {

            // Esto significa que el usuario manipulo tokens 

            this.userIncorrect =
              "Este usuario ha sufrido un intento de hackeo, para desbloquearlo sigue los pasos enviados a su correo electrónico.";
            elements_child.forEach((e) => {
              e.disabled = false;
            });
            this.botton.disabled = false;
          }
        });
      }
    },
  },
};
</script>
