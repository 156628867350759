<template>
    <div>
        <n-space class="items-skeleton" vertical>
            <n-skeleton v-for="item in skeletonItems" :key="item" height="50px" width="100%" class="items-skequeton-themes"/>
            <n-skeleton height="50px" width="100%" class="items-skequeton-themes"/>
        </n-space>
    </div>
</template>

<script>

    export default{

        data(){
            return{
                skeletonItems: 4
            }
        }

    }

</script>