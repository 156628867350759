<template>
    <div>
        <MenuComponent/>
        <section class="container private">
            <!-- Esta es la entrada del administrados -->
            <HomeView/>
            
            <!-- Esta es la vista publica -->
            <!-- <HomePublicView v-else/> -->
        </section>
    </div>
</template>

<script>

    import MenuComponent from '@/components/private/MenuComponent.vue';
    import HomeView from '@/type/admin/HomeView.vue';
    // import HomePublicView from '@/type/public/HomePublicView.vue'

    export default{
        components: {
            MenuComponent,
            HomeView,
            // HomePublicView
        },
        data(){
            return{ 
                remove: false,
                loading: 0,
            }
        },
        methods:{

            async typeuser(){
                await this.$store.dispatch('getUser').then( () => {
                    this.loading = 95;
                    setTimeout(()=>{
                        this.remove  = true;
                    }, 500)

                });
            },
        },
        computed: {
            
            user() {
                return this.$store.state.user;
            }

        },
        mounted(){

            this.typeuser();

        },
        created(){

            const interval = setInterval(() => {
                this.loading += 5;
                if(this.loading == 100){
                    clearInterval(interval)
                }
            }, 200);

        }
    }

</script>

