import router from '@/router/index';
import store from '@/store/index';
import { token } from '@/helpers/getTokeUser';

const domain = "https://g10-admin.garnierbbdo.com/api/";

function close(){
  localStorage.clear();
  store.dispatch('resetUser');
  router.push({ name: 'login' });
}

const register = async (form) => {
  return await fetch(`${domain}register`, {
    method: "POST",
    body: form,
  }).then((res) => {
    return res;
  });
};

const sendVerify = async (form) => {
  return await fetch(`${domain}account/send_verify`, {
    method: "POST",
    body: form,
  }).then((res) => {
    return res;
  });

};

const activeAccount = async (params) => {
  return await fetch(`${domain}account/verify-account/${params}`, {
    method: "POST",
  }).then((res) => {
    return res;
  });
};

const microsoftAccount = async (form) => {
  return await fetch(`${domain}login-microsoft`, {
    method: "POST",
    body: form,
  }).then((res) => {
    return res;
  });
};


// Login user

const login = async (form) => {
  return await fetch(`${domain}login`, {
    method: "POST",
    body: form,
  }).then((res) => {
    return res;
  });
};

// Unbloqued user

const unbloquedUser = async (form, params) => {
  return await fetch(`${domain}account/unlock-account/${params}`, {
    method: "POST",
    body: form,
  }).then((res) => {
    return res;
  });
};

// Validate token Unbloqued

const ValidateUnbloquedUser = async (params) => {
  return await fetch(`${domain}validation/unlock-account/${params}`, {
    method: "GET",
  }).then((res) => {
    return res;
  });
};

// Send mail for reset password

const ResetPasswordEmail = async (form) => {
  return await fetch(`${domain}login/reset-password`, {
    method: "POST",
    body: form,
  }).then((res) => {
    return res;
  });
};

// Create new password user

const newPassword = async (form, params) => {
  return await fetch(`${domain}login/reset-password/${params}`, {
    method: "POST",
    body: form,
  }).then((res) => {
    return res;
  });
};


// My user

const myUser = async () => {
  return await fetch(`${domain}my-account`, {
    method: "GET",
    headers: await token(),
  }).then(res=>{

    if(res.status == 200){
      return res.json().then(data => {
          return data.data;
      });
    }else{
      close();
    }
    
  }).catch(err => {
    throw new Error(err);
  });

};

// Logout

const logout = async () => {
  return await fetch(`${domain}logout`, {
    method: "POST",
    headers: await token(),
  });
};

const updateMyAccound = async (content) => {

  const data = content;
 
  return await fetch(`${domain}update-my-account`,{
    method: "PUT",
    headers: await token(),
    body: JSON.stringify(data)
  }).then(res => {
    return res;
  });
}

// Active admin

const updateAdmin = async (content) => {

  const data = content;
 
  return await fetch(`${domain}active-administrator`,{
    method: "PUT",
    headers: await token(),
    body: JSON.stringify(data)
  }).then(res => {
    if(res.status == 202){
      return res;
    }else{
      close();
    }
  })


}

const getUsers = async (id) => {

  return await fetch(`${domain}user/${id}`,{
    method: "GET",
    headers: await token(),
  });

}


export {
  register,
  sendVerify,
  activeAccount,
  microsoftAccount,
  login,
  unbloquedUser,
  ValidateUnbloquedUser,
  ResetPasswordEmail,
  newPassword,
  myUser,
  logout,
  updateMyAccound,
  updateAdmin,
  getUsers,
};
