<template>
    <div>
        <MenuComponent />
        <section class="container private">
            <HeaderPrivateView/>
            <section class="admin-modificacion">
                <section class="title">
                    <h1>
                        <span>Papelera</span>
                        <br/>
                        Modificaciones en filtros
                    </h1>
                    <ButtonBackComponent nameRouter="filters" />
                </section>
            </section>
            <section class="container-items trash">
                <section class="all-items" v-if="load">
                    <section
                        class="user-modify"
                        v-for="item in items"
                        :key="item.id"
                    >
                        <section class="items-departaments">
                            <span>
                            {{ item.name }}
                            </span>
                            <button class="restaurar" @click="SendPublic(item.id, $event.currentTarget)">
                                    <i class="bi bi-arrow-clockwise"></i>
                            </button> 
                        </section>
                    </section>
                </section>
                <section class="skelenton-container" v-else>
                    <SkeletonModal/>
                </section>
            </section>
        </section>
    </div>
</template>

<script>
    import MenuComponent from "@/components/private/MenuComponent.vue";
    import HeaderPrivateView from "@/components/private/admin/HeaderPrivateView.vue";
    import SkeletonModal from "@/components/extras/SkeletonModal.vue";
    import ButtonBackComponent from '@/components/buttons/back/ButtonBackComponent.vue';


    import { getFiltersTrash, updateFilter } from '@/helpers/fetchs/filters';

    import { useToast } from "vue-toastification";
    const toast = useToast();

    export default{

        components: {
            MenuComponent,
            HeaderPrivateView,
            SkeletonModal,
            ButtonBackComponent
        }, 
        data(){
            return{
                load: false,
                items: {},
            }
        },
        methods: {

            async filters(){

                const items = await getFiltersTrash();
                this.items = items;

                this.load = true;

            },


            async SendPublic(id, event) {

                event.disabled = true;

                let resturar = {
                    id: id,
                    status: 1,
                };

               this.updateFilter(resturar);

            },

            async updateFilter(data){

                const newFilter = await updateFilter(data);

                if (newFilter.status == 202) {

                        toast.success(`El filtro se restauro con éxito.`, {
                            position: "bottom-right",
                            timeout: 5000,
                            pauseOnHover: true,
                            hideProgressBar: true,
                            icon: false,
                            closeButton: false,
                        });

                    this.filters();
                    this.update = true;

                } else {
                        toast.error(
                            `Tenemos problemas para restaurar este filtro, por favor inténtelo más tarde.`,
                            {
                            position: "bottom-right",
                            timeout: 5000,
                            pauseOnHover: true,
                            hideProgressBar: true,
                            icon: false,
                            closeButton: false,
                            }
                        );

                }   
            }


        },
        created(){
            this.filters();
        }

    }

</script>