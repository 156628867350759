<template>
    <div class="problems-public">
        <FiltersView @newFilter="getProblems" />
        <!-- Items of router -->
        <router-link v-for="item in items" :to="{name: 'problem-public-individual', params: {slug: item.slug}}" :key="item.slug" class="item">
            <section>
                <div class="container-title">
                    <h2>
                        {{ item.title }}
                    </h2>
                    <div v-if="filters" class="filters-problems">
                        <span v-if="textsFilteres.filterClient && textsFilteres.filterClient.id">
                            {{ textsFilteres.filterClient.name }}
                        </span>
                        <span v-if="textsFilteres.filterThemes && textsFilteres.filterThemes.id">
                            {{ textsFilteres.filterThemes.name }}
                        </span>
                        <span v-if="textsFilteres.filterOrder">
                            {{ textsFilteres.filterOrder == 'recent' ? 'Más recientes' : '' }}
                            {{ textsFilteres.filterOrder == 'old' ? 'Menos recientes' : '' }}
                            {{ textsFilteres.filterOrder == 'a-z' ? 'A-Z' : '' }}
                            {{ textsFilteres.filterOrder == 'z-a' ? 'Z-A' : '' }}
                        </span>
                    </div>
                </div>
                <p>
                    {{ item.data }}
                </p>
            </section>
            <button class="view_item">
                <i class="bi bi-arrow-right"></i>
            </button>
        </router-link>
    </div>
</template>


<script>

    import { problemsAllApproved } from '@/helpers/fetchs/publicProblems';
    import FiltersView from '@/components/private/public/FiltersView.vue';


    export default{
        components: {
            FiltersView
        },
        data(){
            return {
                items: [],
                filters: false,
                textsFilteres: {},
            }
        },
        methods: {
            async getProblems(dataFilters){

                const data = {};
                this.items = null;
                const params = this.$route.query;

                if(params.client){
                    data.client = parseInt(params.client);
                    localStorage.setItem('client', params.client);
                }

                if(params.theme){
                    data.themes = parseInt(params.theme);
                    localStorage.setItem('theme', params.theme);
                }

                if(params.order){
                    data.order = params.order;
                    localStorage.setItem('order', params.order);
                }

                if(params.search){
                    localStorage.setItem('search', params.search);
                    data.search = params.search;
                }

                const exist = Object.keys(data);

                if(exist.length > 0){
                    this.filters = true;
                }else{
                    this.filters = false;
                }

                const allItems = await problemsAllApproved(data);
                if(allItems != 404){
                    this.items = allItems;
                }else{
                    this.items = null;
                }
                
                this.textsFilteres = await dataFilters;

            }
        },
        mounted(){
            // this.getProblems();
        }

    }

</script>
