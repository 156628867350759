<template>
    <div class="modal-sending-problem" :class="close ? 'active': ''">
        <section class="content_modal">
            <section v-if="!save">
                <p>

                    <ul>
                        <li>
                            <span v-if="typeModal == 'create'"> <strong> {{ text }} </strong> </span>
                            <span v-if="typeModal == 'delete'"> <strong> Eliminando la problemática </strong> </span>
                            <span v-if="typeModal == 'save'"> <strong> Publicando la problemática </strong> </span>
                        </li>
                    </ul>

                    <section>
                        <n-spin size="large"  :show="true"></n-spin>
                    </section>

                    <ul>
                        <li>
                            <span>
                                Gracias por formar parte de nuestra comunidad
                                <i class="bi bi-balloon-heart"></i>
                            </span>
                        </li>
                    </ul>

                </p>
            </section>
            <section v-else>
                <p>
                    <ul>
                        <li>
                            <span v-if="typeModal == 'create'"> <strong> La problemática se guardo con éxito </strong> </span>
                            <span v-if="typeModal == 'delete' || typeModal == 'save'"> <strong> La problemática se elimino con éxito </strong> </span>
                        </li>
                    </ul>
                    <section>
                        <Vue3Lottie 
                            :pauseAnimation="true"
                            :height="100"
                            :width="100"
                            :animationData="Check"
                            :loop="1"
                        />
                    </section>
                    <ul>
                        <li>
                            <span v-if="typeModal == 'create'">Cuando la problemática sea aprobada te notificaremos por un correo electrónico.</span>
                            <span v-if="typeModal == 'delete'">
                                Gracias por formar parte de nuestra comunidad
                                <i class="bi bi-balloon-heart"></i>
                            </span>
                            <span v-if="typeModal == 'save'">
                                Gracias por formar parte de nuestra comunidad
                                <i class="bi bi-balloon-heart"></i>
                            </span>
                        </li>
                    </ul>
                </p>
                <button class="close" @click="cerrar">
                    <img src="@/assets/img/close.svg" alt="" />
                </button>
            </section>
        </section>
    </div>
</template>

<script>

    // Lottie animation
    import { Vue3Lottie } from "vue3-lottie";
    import "vue3-lottie/dist/style.css";
    import Check from '@/assets/animation/successful.json'

    export default{
        props: {
            notify: Boolean,
            successful: Boolean,
            typeModal: {
                type: String,
                default: "create"
            }
        },
        components: {
            Vue3Lottie,
        },
        data(){

            return{
                Check,
                save: false,
                text: 'Guardando la problemática',
                close: false,
            }

        },
        methods: {
            cerrar(){
                this.close = true;
                setTimeout(()=>{
                    this.$emit('closeModal', true);
                }, 410)
            }
        },
        watch: {
            notify(){
                this.text = 'Notificando a los administradores'
            },

            successful(){
                this.save = true;
            }
        }

    }

</script>