<template>
    <div>
        <HeaderView/>
        <section>
            <RouterLink :to="{name: 'newProblem'}" class="new-problems-boton items">
                <span>
                    <img src="@/assets/img/escudo.svg" alt="">
                    Nueva problemática
                </span>
                <i class="bi bi-plus"></i>
            </RouterLink>
        </section>
        <AllProblemsView/>
    </div>
</template>

<script>

    import HeaderView from '@/components/private/public/HeaderView.vue'
    import AllProblemsView from '@/views/private/public/AllProblemsView.vue'

    export default{

        components: {
            HeaderView,
            AllProblemsView
        }

    }

</script>