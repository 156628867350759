import router from "@/router/index";
const domain = "https://g10-admin.garnierbbdo.com/api/";
import store from '@/store/index';
import { token } from '@/helpers/getTokeUser';

function close() {
  localStorage.clear();
  store.dispatch('resetUser');
  router.push({ name: "login" });
}

const saveTheme = async (data) => {
  return await fetch(`${domain}theme`, {
    method: "POST",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

const teamsPublic = async () => {

  return await fetch(`${domain}theme-public`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 200) {
      return res.json().then((data) => {
        return data.data.items;
      });
    } else if (res.status == 404) {
      return null;
    } else {
      close();
    }
  });
};

const teams = async () => {

  return await fetch(`${domain}theme`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 200) {
      return res.json().then((data) => {
        return data.data.items;
      });
    } else if (res.status == 404) {
      return null;
    } else {
      close();
    }
  });
};

const updateTheme = async (data) => {
  return await fetch(`${domain}theme`, {
    method: "PUT",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

const deleteThemes = async (data) => {

  return await fetch(`${domain}theme-remove`, {
    method: "PUT",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

const teamsTrash = async () => {

  return await fetch(`${domain}theme-remove`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 200) {
      return res.json().then((data) => {
        return data.data.items;
      });
    } else if (res.status != 404) {
      close();
    } else {
      return null;
    }
  });
};

const activeThemes = async (data) => {
  const token = localStorage.getItem("token");

  return await fetch(`${domain}theme-active`, {
    method: "PUT",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

export {
  saveTheme,
  teams,
  updateTheme,
  deleteThemes,
  teamsTrash,
  activeThemes,
  teamsPublic
};
