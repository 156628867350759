<template>
  <div class="login center h-v-100">
    <HeaderAuth />
    <section class="container form">
        <h1 v-if="!sendingMail">
            Solicitar restablecimiento
        </h1>
      <form v-if="!sendingMail" v-on:submit.prevent="sendMail()">
        <section class="individual">
          <label for="email" class="mb-3">
            <section class="relative_inputs helvicaFont mb-3">
              <input
                type="email"
                id="email"
                v-model="value.email"
                autocomplete="username"
                autofocus
                placeholder="Correo electrónico"
              />
              <button
                type="submit"
                :disabled="botton.disabled"
                class="send_move"
              >
                <svg v-if="!loading" width="22" viewBox="0 0 45 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.9 40.17L43.44 20.59L24.9 1" stroke="white" stroke-miterlimit="10"/>
                    <path d="M43.45 20.59H0" stroke="white" stroke-miterlimit="10"/>
                </svg>
                <div class="loadingItem" v-else></div>
              </button>
            </section>
            <p class="error email_error" v-if="existUser" :class="existUser ? 'active' : ''" >{{ existUser }}</p>
            <div class="error email_error"/>
            <router-link :to="{name: 'login'}" class="mt-3 reset_password_link">¿Desea iniciar sesión?</router-link>
          </label>
        </section>
      </form>
      <Vue3Lottie v-if="sendingMail" :pauseAnimation="true" :animationData="DogJSON" :height="500" :width="500" />
    </section>
    <FooterAuth />
  </div>
</template>

<script>
import HeaderAuth from "@/components/public/HeaderAuth.vue";
import FooterAuth from "@/components/public/FooterAuth.vue";
import validation from "@/helpers/validation";
import {
  ResetPasswordEmail,
  
} from "@/helpers/fetchs/user";
import { Vue3Lottie } from 'vue3-lottie'
import DogJSON from '@/assets/animation/send_message.json'

import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  components: {
    HeaderAuth,
    FooterAuth,
    Vue3Lottie
  },
  data() {
    return {
      DogJSON,
      value: {
        email: "",
      },
      botton: {
        disabled: false,
      },
      validation_all: {
        inputs : 0
      },
      existUser: '',
      sendingMail: false,
      loading: false,
    };
  },
  methods: {
    // Validation all

    validation() {
      this.validation_all.inputs = 0;
      const elements_child = document.querySelectorAll("input");
      const type = "individual";
      elements_child.forEach((e) => {
        if (!validation(e, type)) {
          this.validation_all.inputs += 1;
        }
      });
    },

    focus_button() {
      this.botton.registro = "Registrarse";
    },

    blur_button() {
      this.botton.registro = "";
    },

    async sendMail() {
      this.validation();
      this.userIncorrect = "";
      const lengt_inputs = document.querySelectorAll("input").length;

      if (lengt_inputs == this.validation_all.inputs) {
        const elements_child = document.querySelectorAll("input");
        elements_child.forEach((e) => {
          e.disabled = true;
        });

        this.botton.disabled = true;
        this.loading = true;
      }

      if(this.validation_all.inputs == 1){

        this.sendingMail = true

        const form = new FormData();
        form.append('email', this.value.email);
        this.existUser = '';


        await ResetPasswordEmail(form)
        .then(res=>{

            this.loading = false;

            const elements_child = document.querySelectorAll("input");
                elements_child.forEach((e) => {
                    e.disabled = false;
                });

                this.botton.disabled = false;



            if(res.status == 404){
                this.sendingMail = false;
                toast.error('Este usuario no está registrado.',{ 
                        position: 'bottom-right',
                        timeout: 5000,
                        pauseOnHover: true,
                        hideProgressBar: true,
                        icon: false,
                        closeButton: false
                        });
                
            }else if(res.status == 429){
                this.sendingMail = false;
                toast.error('Has intentado realizar demasiadas peticionesUsers, por favor inténtelo dentro de unos minutos.',{ 
                        position: 'bottom-right',
                        timeout: 5000,
                        pauseOnHover: true,
                        hideProgressBar: true,
                        icon: false,
                        closeButton: false
                        });
            }else if(res.status == 201){
                this.value.email = ''
                this.sendingMail = false;
                toast.success('Se envió a su correo electrónico un enlace para restablecer su contraseña.',{ 
                        position: 'bottom-right',
                        timeout: 5000,
                        pauseOnHover: true,
                        hideProgressBar: true,
                        icon: false,
                        closeButton: false
                        });
            }else if(res.status == 403 || res.status == 401){
                this.value.email = ''
                this.sendingMail = false;
                toast.error(`Este usuario no puede restablecer la contraseña`,{ 
                  position: 'bottom-right',
                  timeout: 5000,
                  pauseOnHover: true,
                  hideProgressBar: true,
                  icon: false,
                  closeButton: false
                  });
            }else{
                this.sendingMail = false;
                toast.error('Ha ocurrido un problema con su solictud, por favor intentelo mas tarde.',{ 
                        position: 'bottom-right',
                        timeout: 5000,
                        pauseOnHover: true,
                        hideProgressBar: true,
                        icon: false,
                        closeButton: false
                        });
            }



        })

      }

    },
  },
};
</script>
