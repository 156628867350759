<template>
    <div class="filters">
        <section class="actios-filter" :class="{'active': filtersClass, 'hidden': hiddenItems}">
            <section class="all_bottons">
                <button @click="viewFilter" :class="{'active': filtersClass, 'hidden': hiddenItems}">
                    Filtros
                </button>
                <section>
                    <section>
                        <section v-if="filterClient.id" class="filter-item-view">
                            Cliente: 
                            <span>
                                {{filterClient.name}}
                                <button @click="filterClients(filterClient.id)">
                                    <i class="bi bi-x"></i>
                                </button>
                            </span>
                        </section>
                        <section v-if="filterThemes.id" class="filter-item-view">
                            Temática:
                            <span>
                                {{filterThemes.name}}
                                <button @click="themesClients(filterThemes.id, null)">
                                    <i class="bi bi-x"></i>
                                </button>
                            </span>
                        </section>
                        <section v-if="filterOrderActive" class="filter-item-view">
                            <span>
                                {{ filterOrderActive == 'recent' ? 'Más recientes' : '' }}
                                {{ filterOrderActive == 'old' ? 'Menos recientes' : '' }}
                                {{ filterOrderActive == 'a-z' ? 'A-Z' : '' }}
                                {{ filterOrderActive == 'z-a' ? 'Z-A' : '' }}
                                <button @click="filterOrder(filterOrderActive)">
                                    <i class="bi bi-x"></i>
                                </button>
                            </span>
                        </section>
                    </section>
                </section>
                <section>
                    <form onsubmit="return false;"  v-if="search" :class="{'active': viewSearch, 'activeFocus': focusSearch}">
                        <input @focus="focusForm" @blur="blurForm" type="text" placeholder="Buscar problemática" v-model="searchFilter">
                        <button type="button">
                            <i class="bi bi-search"></i>
                        </button>
                    </form>
                </section>
            </section>
            <section class="filtros_copy">
                <AcccionsFiltersView class="copyFilters" @updateFilters="updateFilter" />
                <!-- <button class="close" @click="removeFilters">
                    <i class="bi bi-x-lg"></i>
                </button> -->
            </section>
        </section>
        <section class="items-filters"  :class="{'active': filtersClass, 'hidden-filters': hiddenFilters}">
            <section v-for="item in filtersActive" :key="item.id">

                <h2 v-if="item.id == 1">{{item.name}}</h2>
                <h2 v-if="item.id == 2">{{ item.name }}</h2>
                <h2 v-if="item.id == 3">{{ item.name }}</h2>

                <ul v-if="item.id == 1">
                    <li v-for="item in clientsItems" :key="item.id">
                        <button @click="filterClients(item.id)" :class="item.id == filterClient.id ? 'active-filter' : ''" >
                            {{ item.name }}
                        </button>
                    </li>
                </ul>


                <ul v-if="item.id == 2">
                    <li v-for="item in themesItems" :key="item.id">
                        <button class="theme" @click="themesClients(item.id, item.color)" :class="item.id == filterThemes.id ? 'active-filter' : ''">
                            <span  
                                :style="{
                                    'background': item.color
                                }"
                            ></span>
                            {{ item.name }}
                        </button>
                    </li>
                </ul>

                <ul v-if="item.id == 3">
                    <li>
                        <button :class="filterOrderActive == 'recent' ? 'active-filter' : ''" @click="filterOrder('recent')">Más recientes</button>
                    </li>
                    <li>
                        <button :class="filterOrderActive ==  'old' ? 'active-filter' : ''" @click="filterOrder('old')">Menos recientes</button>
                    </li>
                    <li>
                        <button :class="filterOrderActive == 'a-z' ? 'active-filter' : ''" @click="filterOrder('a-z')">A-Z</button>
                    </li>
                    <li>
                        <button :class="filterOrderActive ==  'z-a' ? 'active-filter' : ''" @click="filterOrder('z-a')">Z-A</button>
                    </li>
                </ul>

            </section>
        </section>
    </div>
</template>

<script>

    import { getClientsPublic } from '@/helpers/fetchs/clients';
    import { teamsPublic } from '@/helpers/fetchs/themes';
    import { getFiltersPublic } from '@/helpers/fetchs/filters';
    import AcccionsFiltersView from '@/components/private/public/AccionsFiltersView.vue'

    export default{
        components: {
            AcccionsFiltersView
        },
        data(){
            return{
                filtersClass: false,
                hiddenItems: false,
                hiddenFilters: false,
                searchFilter: '',
                viewSearch: false,
                focusSearch: false, 
                clientsItems: {},
                themesItems: {},
                filtersActive: {},
                search: false,
                filterClient: {
                    id: null,
                    name: null
                },
                filterThemes: {
                    id: null,
                    name: null
                },
                filterOrderActive: null,
                copiar: false,
                dataNameFilter: {}
            }
        },

        methods: {

            // View filter with all items

            viewFilter(){

                if(this.filtersClass == false){
                    this.filtersClass = true;
                    document.body.style.overflow = "hidden"
                }else{
                    this.removeFilters();
                }

            },

            // Remove the div with all filters

            removeFilters(){

                this.hiddenFilters = true;
                this.hiddenItems = true;

                setTimeout(()=>{
                    document.body.style.overflow = "auto"
                    this.hiddenFilters = false;
                    this.filtersClass = false;
                    this.hiddenItems = false;
                }, 600)

            },

            // Generate a animation with the input of the form

            // Start animation

            focusForm(){
                this.focusSearch = true;
            },

            blurForm(){
                this.focusSearch = false;
            },

            // End animation

            filtesItems(){
                if(this.filterClient.id){
                    //const items = this.clientsItems.find(item => item.id === 49)

                    const countItems =  Object.keys(this.clientsItems).length

                    if(countItems > 0){
                        const items = this.clientsItems.find(item => item.id == parseInt(this.filterClient.id))
                        this.filterClient.name = items.name;
                    }
                }

                if(this.filterThemes.id){

                    const countItems =  Object.keys(this.themesItems).length

                    if(countItems > 0){
                        const items = this.themesItems.find(item => item.id == parseInt(this.filterThemes.id))
                        this.filterThemes.name = items.name;
                    }

                }

            },

            // Insert filter in the url

            async updateQueryParam(key, value) {
                const query =  { ...this.$route.query, [key]: value };
                await this.$router.push({ query });
                this.filtesItems();
            },

            // Remove filter on url

            async removeQueryParam(key) {
                const query = { ...this.$route.query };
                delete query[key];
                await this.$router.push({ query });
                this.filtesItems();
            },

            // View all clients in the filters

            async clientsFilters(){
                const clients = await getClientsPublic();
                this.clientsItems = clients;
                this.filtesItems();

            },

            // View all themes in the filters

            async themesFilter(){

                const themes = await teamsPublic();
                this.themesItems = themes;
                this.filtesItems();

            },

            // Filters publics

            async filterView(){

                const filters = await getFiltersPublic();
                this.filtersActive = filters;

                const itemExists = this.filtersActive.some(item => item.id === 4);

                if(itemExists){
                    this.search = true;
                }else{
                    this.search = false;
                }

                this.filtesItems();

            },

            async filterClients(id){

                this.removeFilters();

                if(this.filterClient.id == id){
                    this.filterClient.id = null;
                    localStorage.removeItem('client');
                    await this.removeQueryParam('client');
                }else{
                    await this.updateQueryParam('client', id);
                    localStorage.setItem('client', id);
                    this.filterClient.id = id;
                }

                this.filtesItems();

                this.$emit("newFilter",{
                    filterClient: this.filterClient,
                    filterThemes: this.filterThemes,
                    filterOrder: this.filterOrderActive
                });

            },

            async themesClients(id, color){

                this.removeFilters();

                if(this.filterThemes.id == id){

                    this.filterThemes.id = null;
                    localStorage.removeItem('theme');
                    await this.removeQueryParam('theme');
                    const root = document.documentElement;
                    root.style.setProperty('--theme-color', '#00CC79');

                }else{
                    this.filterThemes.id = id;
                    localStorage.setItem('theme', id);
                    localStorage.setItem('theme-color', color);
                    const root = document.documentElement;
                    root.style.setProperty('--theme-color', color);
                    await this.updateQueryParam('theme', id);
                }

                this.filtesItems();

                this.$emit("newFilter",{
                    filterClient: this.filterClient,
                    filterThemes: this.filterThemes,
                    filterOrder: this.filterOrderActive
                });

            },

            async filterOrder(type){
                this.removeFilters();

                if(this.filterOrderActive == type){

                    this.filterOrderActive = null;
                    localStorage.removeItem('order');
                    await this.removeQueryParam('order');

                }else{
                    await this.updateQueryParam('order', type);
                    localStorage.setItem('order', type);
                    this.filterOrderActive = type;    
                }

                this.filtesItems();

                this.$emit("newFilter",{
                    filterClient: this.filterClient,
                    filterThemes: this.filterThemes,
                    filterOrder: this.filterOrderActive
                });

            },

            updateFilter(){

                if (localStorage.getItem('search')) {

                    const search = localStorage.getItem('search');
                    this.updateQueryParam('search', search)
                    this.viewSearch = true;
                    this.searchFilter = search;

                }

                setTimeout(() => {
                    if (localStorage.getItem('client')) {

                        const idClient = localStorage.getItem('client');
                        this.updateQueryParam('client', idClient);
                        
                        this.filterClient.id = idClient;

                    }
                }, 100);

                setTimeout(() => {

                    if (localStorage.getItem('theme')) {

                        const idThemes = localStorage.getItem('theme');
                        const color = localStorage.getItem('theme-color');

                        const root = document.documentElement;
                        root.style.setProperty('--theme-color', color);

                        this.updateQueryParam('theme', idThemes);

                        this.filterThemes.id = idThemes;

                    }

                }, 200);

                setTimeout(() => {
                    if (localStorage.getItem('order')) {

                        const orderType = localStorage.getItem('order');
                        this.updateQueryParam('order', orderType);

                        this.filterOrderActive = orderType;

                    }

                    this.$emit("newFilter",{
                        filterClient: this.filterClient,
                        filterThemes: this.filterThemes,
                        filterOrder: this.filterOrderActive
                    });

                }, 300);
            }

        },
        watch: {
            
            async searchFilter(value){

                if(value.length){
                    this.viewSearch = true;
                    await this.updateQueryParam('search', value);
                    localStorage.setItem('search', value);
                }else{
                    this.viewSearch = false;
                    await this.removeQueryParam('search');
                    localStorage.removeItem('search');
                }

                this.$emit("newFilter",{
                    filterClient: this.filterClient,
                    filterThemes: this.filterThemes,
                    filterOrder: this.filterOrderActive
                });
                this.filtesItems();
            }
        },
        mounted(){
            this.$emit("newFilter",{
                filterClient: this.filterClient,
                filterThemes: this.filterThemes,
                filterOrder: this.filterOrderActive
            });
        },
        created(){

            this.clientsFilters();
            this.themesFilter();
            this.filterView();

            this.updateFilter();

        }

    }

</script>