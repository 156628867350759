<template>
    <router-view/>
</template>




<style lang="scss">

  /* Font Family */

  /* Globla Styles */

  @import './assets/css/_variables.scss';
  @import './assets/css/global.scss';

  /* Normalize CSS */
  // @import url(./assets/css/normalize.css);

</style>
