import router from "@/router/index";
import store from '@/store/index';

const domain = "https://g10-admin.garnierbbdo.com/api/";

import { token } from '@/helpers/getTokeUser';

function close() {
  localStorage.clear();
  store.dispatch('resetUser');
  router.push({ name: "login" });
}

const getFilters = async () => {

  return await fetch(`${domain}filters`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 404) {
      return null;
    } else if (res.status == 200) {
      return res.json().then((data) => {
        return data.data.items;
      });
    } else {
      close();
    }
  });
};

const getFiltersPublic = async () => {

  return await fetch(`${domain}filters-public`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 404) {
      return null;
    } else if (res.status == 200) {
      return res.json().then((data) => {
        return data.data.items;
      });
    } else {
      close();
    }
  });
};

const getFiltersTrash = async () => {

  return await fetch(`${domain}filters-trash`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 404) {
      return null;
    } else if (res.status == 200) {
      return res.json().then((data) => {
        return data.data.items;
      });
    } else {
      close();
    }
  });
};

const updateFilter = async (data) => {

  return await fetch(`${domain}filters`, {
    method: "PUT",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

export { getFilters, updateFilter, getFiltersTrash, getFiltersPublic };
