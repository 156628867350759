<template>
    <div>
        <RouterLink :to="{ name: nameRoute }" class="trash">
            Papelera <i class="bi bi-trash3"></i>
        </RouterLink>
    </div>
</template>

<script>

    export default{

        props:{
            nameRoute: String
        }

    }

</script>