import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vuesax from 'vuesax3'
import 'vuesax3/dist/vuesax.css'
import 'aos/dist/aos.css';

// Azure 
import Emitter from 'tiny-emitter';

// Lottie Animation 
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

// Naive libreri
// import naivePresent from 'naive-ui/es/preset'
import naive from "naive-ui";

// Notification
import Toast from "vue-toastification";
import 'vue-toastification/dist/index.css';


const app = createApp(App);

app.config.globalProperties.$msalInstance = {}
app.config.globalProperties.$emitter = new Emitter();


app.use(store)
    .use(router)
    .use(naive)
    .use(Vuesax)
    .use(Vue3Lottie)
    .mount('#app')

try {
    app.use(Toast);
} catch (error) {
    console.error('Error al utilizar vue-toastification:', error);
}
