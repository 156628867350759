import { descript } from "@/helpers/secret/encrypt_decrypt";

function getCookieValue(cookieName) {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
  
    for(var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
  
    return "";
  }
  

const token = async () => {
    let keyToken = getCookieValue("__SESSIONKEY");
    let userToken = getCookieValue("__SESSIONDATA");
    
    const keyArray = keyToken.split(",").map((byte) => parseInt(byte.trim(), 10));
    const secretKeyArray = new Uint8Array(keyArray);
    const tokenAwait = await descript(userToken, secretKeyArray);
    
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenAwait}`,
    };

    return header

}

export { token };