import { createStore } from 'vuex'
import { myUser } from '@/helpers/fetchs/user'

export default createStore({
  state: {

    user: {},

  },
  getters: {
  },
  mutations: {

    setUser(state, userData) {
      state.user = userData;
    },

    resetUser(state) {
      state.user = {};
      localStorage.clear();
    },
    
  },

  actions: {

    async getUser(context) {
      try {
        const data = await myUser();  
        context.commit('setUser', data);
      } catch (error) {
        console.error(error);
      }
    },
    
  },

  modules: {
  }
})
