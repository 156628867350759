<template>
  <div v-if="viewModal" class="modal" :class="openModal ? 'active' : ''">
    <section class="content_modal" v-if="presentation">
        <section>
          <p>
            <strong>Bienvenido al sistema de problemáticas.</strong>
            <br/> 
            Antes de continuar, necesitamos que por favor rellenes los campos faltantes a continuación. 
            <br/>
            Por favor, ingresa el equipo al que perteneces y adjunta una fotografía para poder identificarlo.
            <br/>
            ¡Muchas gracias!
            <br/>
            <button class="item" @click="closeModal()">
              <span>Comenzar </span>
            </button>
          </p>
          <button v-if="!cerrarSesion" class="close" @click="closeModal()">
            <img src="@/assets/img/close.svg" alt="" />
          </button>
        </section>
    </section>
    <section class="content_modal" v-else>
      <section v-if="updated == 'update' || updated == 'updated'" class="cambiando-user">
        <n-spin>
          <template #description> Actualizando el usuario</template>
        </n-spin>
      </section>
      <section v-if="!newPassword">
        <p v-html="text"></p>
        <button v-if="!cerrarSesion" class="close" @click="closeModal()">
          <img src="@/assets/img/close.svg" alt="" />
        </button>
        <button @click="changeLogin" :class="cerrarSesion ? 'active' : ''">
          <span>{{ textoBoton }}</span>
        </button>
      </section>
      <section v-if="acceptChange" class="cambiando-user">
        <n-spin>
          <template #description> {{ textCambiando }} </template>
        </n-spin>
      </section>
      <section v-if="newPassword" class="newPassword">
        <p>Ingrese la nueva contraseña</p>
        <button v-if="!cerrarSesion" class="close" @click="closeModal()">
          <img src="@/assets/img/close.svg" alt="" />
        </button>
        <form action="">
          <label for="password" class="relative_inputs helvicaFont">
            <section class="relative_inputs helvicaFont">
              <input
                type="password"
                v-model="value.password"
                id="password"
                class="reset_password"
                v-on:focus="focus_input($event)"
                v-on:blur="blur_input($event)"
                autocomplete="new-password"
                :disabled="acceptChange"
                placeholder="Contraseña"
              />
            </section>
            <div class="error" />
          </label>
          <label for="repeat" class="relative_inputs helvicaFont">
            <section class="relative_inputs helvicaFont">
              <input
                type="password"
                name="repeat"
                v-model="value.repeat"
                id="repeat"
                class="reset_password"
                v-on:focus="focus_input($event)"
                v-on:blur="blur_input($event)"
                autocomplete="new-password"
                :disabled="acceptChange"
                placeholder="Repetir contraseña"
              />
            </section>
            <div class="error" />
          </label>
          <button @click="createPassword" class="sendPassword" type="button">
            Establecer contraseña
          </button>
        </form>
      </section>
    </section>
  </div>
</template>

<script>
import {
  updateMyAccound,

} from "@/helpers/fetchs/user";
import validation from "@/helpers/validation";

export default {
  props: {
    view: Boolean,
    close: Boolean,
    updated: String,
    presentation: Boolean,
  },
  data() {
    return {
      text: ` <strong>¿Deseas iniciar sesión con Microsoft?</strong>
                        <br/> 
                        Si decides iniciar sesión con Microsoft, ya no podrás acceder utilizando tu contraseña y usuario habituales. Será necesario utilizar exclusivamente el inicio de sesión de Microsoft.
                        <br/>
                        Si prefieres mantener tu forma de inicio de sesión actual, simplemente cierra esta ventana y podrás seguir accediendo con tu contraseña y usuario como siempre.
                        <br/>
                        Haz clic en "Aceptar" para comenzar con tu cuenta de Microsoft.
                        <br/>
                        ¡Gracias por ser parte de nuestra comunidad y disfruta de tu experiencia mejorada de inicio de sesión!`,
      openModal: false,
      viewModal: false,
      acceptChange: false,
      tipoCambio: null,
      newPassword: false,
      value: {
        password: "",
        repeat: "",
      },
      validation_all: {
        inputs: 0,
        email: "",
      },
      textCambiando: "Cambiando el tipo inicio de sesión",
      textoBoton: "ACEPTO",
      cerrarSesion: false,
    };
  },
  methods: {
    closeModal() {

      if(localStorage.getItem('presentation')){
        localStorage.removeItem('presentation');
      }

      this.$emit("cambio-cancelado", true);
      this.openModal = false;
      setTimeout(() => {
        this.viewModal = false;
        this.newPassword = false;
      }, 400);
    },

    async changeLogin() {
      if (this.tipoCambio == "microsft") {
        (this.textCambiando = "Cambiando el tipo inicio de sesión"),
          (this.acceptChange = true);
        const data = {
          microsft: "true",
        };
        await updateMyAccound(data).then((res) => {
          if (res.status == 202) {
            this.openModal = false;
            setTimeout(() => {
              this.viewModal = false;
              this.acceptChange = false;
              this.$emit("login-actualizado", true);
            }, 400);
          }
        });
      } else {
        this.newPassword = true;
      }

      if (this.cerrarSesion) {

        this.clearCookies();

        this.$router.push({ name: "login" });
        localStorage.clear();
      }
    },

    clearCookies(){
      var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
      }
    },

    focus_input(element) {
      const element_child = element.target.parentNode;
      element_child.classList.add("active");
    },

    blur_input(element) {
      const element_child = element.target.parentNode;
      const element_input = element.target;
      const type = "individual";

      element_input.value
        ? element_child.classList.add("active")
        : element_child.classList.remove("active");

      validation(element_input, type);
    },

    validation() {
      this.validation_all.inputs = 0;
      const elements_child = document.querySelectorAll("input.reset_password");
      const type = "individual";
      elements_child.forEach((e) => {
        if (!validation(e, type)) {
          this.validation_all.inputs += 1;
        }
      });
    },

    async createPassword() {
      this.validation();
      if (this.validation_all.inputs == 2) {
        this.textCambiando = "Estableciendo la nueva contraseña.";
        this.acceptChange = true;

        const data = {
          microsft: "false",
          password: this.value.password,
        };

        await updateMyAccound(data).then((res) => {
          if (res.status == 202) {
            this.value.password = "";
            this.value.repeat = "";
            this.openModal = false;
            setTimeout(() => {
              this.viewModal = false;
              this.newPassword = false;
              this.acceptChange = false;
              this.$emit("login-actualizado", false);
            }, 400);
          }
        });
      }
    },
  },
  watch: {
    view(value) {

      if (value == true) {
        this.tipoCambio = "microsft";
        this.viewModal = true;
        setTimeout(() => {
          this.openModal = true;
        }, 10);

        this.text = ` <strong>¿Deseas iniciar sesión con Microsoft?</strong>
                        <br/> 
                        Si decides iniciar sesión con Microsoft, ya no podrás acceder utilizando tu contraseña y usuario habituales. Será necesario utilizar exclusivamente el inicio de sesión de Microsoft.
                        <br/>
                        Si prefieres mantener tu forma de inicio de sesión actual, simplemente cierra esta ventana y podrás seguir accediendo con tu contraseña y usuario como siempre.
                        <br/>
                        Haz clic en "Aceptar" para comenzar con tu cuenta de Microsoft.
                        <br/>
                        ¡Gracias por ser parte de nuestra comunidad y disfruta de tu experiencia mejorada de inicio de sesión!`

      } else if (value == false) {
        this.tipoCambio = "normal";

        this.text = ` <strong>¿Deseas iniciar sesión con usuario y contraseña?</strong>
                        <br/> 
                        Si decides iniciar sesión con usuario y contraseña, ya no podrás acceder utilizando tu usuario de Microsoft. Será necesario utilizar exclusivamente el usuario y la contraseña.
                        <br/>
                        Si prefieres mantener tu forma de inicio de sesión actual, simplemente cierra esta ventana y podrás seguir accediendo con tu cuenta de Microsoft como siempre.
                        <br/>
                        Haz clic en "Aceptar" para establecer la nueva contraseña de inicio de sesión.
                        <br/>
                        ¡Gracias por ser parte de nuestra comunidad y disfruta de tu experiencia mejorada de inicio de sesión!`;
        this.viewModal = true;
        setTimeout(() => {
          this.openModal = true;
        }, 10);
      }
    },

    close() {
      localStorage.clear();
      this.viewModal = true;
      this.textoBoton = "CERRAR SESIÓN";
      this.cerrarSesion = true;
      setTimeout(() => {
        this.openModal = true;
      }, 10);
      this.text = ` <strong>¡Has cambiado tu correo electrónico!</strong>
                    <br/> 
                    Acabamos de enviarte un correo electrónico muy importante. Queremos asegurarnos de que eres el legítimo dueño de esa dirección de correo, por lo que es crucial que confirmes tu identidad. Mientras tanto, por razones de seguridad, hemos tomado la precaución de cerrar temporalmente tu sesión. Pero no te preocupes, una vez que hayas confirmado tu correo, volverás a tener acceso completo. ¡Estamos aquí para protegerte!`;
    },

    updated(value){

      if(value == 'updated'){
        this.closeModal();
      }

    }
  },
};
</script>
