<template>
    <div class="new-problem-container">
        <MenuComponent/>
        <HeaderView/>
        <ModalProblemsCreateView v-if="sending" :notify="notify" :successful="finalizado" @closeModal="closeModal"/>
        <section class="container private">
            
            <section class="new-problem">
                <h1>Nueva problemática</h1>
                <form action="">
                    <section class="double_label">
                        <input type="text" class="problem" placeholder="Título" v-model="title">
                        <input type="text" class="problem" placeholder="Enlace del miro" v-model="miroValue">
                    </section>
                    <textarea name="" class="problem" id="" cols="30" rows="10" placeholder="Data" v-model="data"></textarea>
                    <n-dynamic-input
                        v-model:value="links"
                        placeholder="Inserte el enlace"
                        :min="1"
                        :max="6"
                        :class="addLinksNull ? 'error-native' :  '' "
                    />
                    <n-tree-select
                        multiple
                        checkable
                        placeholder="Seleccione las temáticas"
                        :options="themes"
                        :value="treeValue"
                        @update:value="treeValue = $event"
                        :class="treeValueNull ? 'error-native' :  '' "
                    />
                    <section class="divide">
                        <n-select
                            v-model:value="clientsValue"
                            filterable
                            placeholder="Seleccione el cliente"
                            :options="clients"
                            :class="clientsNull ? 'error-native' :  '' "
                        />
                        <button type="button" @click="sendProblem">
                            <img src="@/assets/img/flecha.svg" alt="" />
                        </button>
                    </section>
                </form>
            </section>

        </section>
    </div>
</template>

<script>

    import MenuComponent from '@/components/private/MenuComponent.vue';
    import HeaderView from '@/components/private/public/HeaderView.vue';
    import {
        getClientsPublic,
    } from "@/helpers/fetchs/clients";
    import {
        teamsPublic
    } from "@/helpers/fetchs/themes";
    import ModalProblemsCreateView from '@/components/private/public/ModalProblemsCreateView.vue';
    import {
        saveProblem,
        notifyUsers
    } from '@/helpers/fetchs/problems';
    import { useToast } from "vue-toastification";

    const toast = useToast();


    export default{

        components:{
            MenuComponent,
            HeaderView,
            ModalProblemsCreateView
        },
        data(){
            return{
                links: [""],
                clients: [],
                themes: [],
                send: true,
                treeValue: [],
                treeValueNull: false,
                addLinksNull: false,
                clientsNull: false,
                clientsValue: null,
                sending: false,
                notify: false,
                finalizado: false,
                title: '',
                data: '',
                miroValue: ''
            }
        },
        methods: {
            async getClients(){

                const clients = await getClientsPublic();

                const transformObject = Object.keys(clients).map( key => {
                    const { id, name, ...rest } = clients[key];
                    return {
                        value: id,
                        label: name,
                        ...rest
                    }
                })

                this.clients = transformObject;

            },

            async getThemes(){

                const themesAll = await teamsPublic();


                const obj = themesAll;

                const transformObject = Object.keys(obj).map( key => {
                    const { id, name, ...rest } = obj[key];
                    return {
                        key: id,
                        label: name,
                        ...rest
                    }
                })

                this.themes = transformObject;

            },

            sendProblem(){

                this.send = true;

                const input = document.querySelectorAll('input.problem, textarea.problem');

                input.forEach(e=>{
                    if(!e.value){
                        this.send = false;
                        e.classList.add('err')
                    }else{
                        e.classList.remove('err')
                    }
                });
                

                if(!this.treeValue.length){
                    this.treeValueNull = true;
                    this.send = false;
                }else{
                    this.treeValueNull = false;
                }
                
                if(!this.links[0].length){
                    this.addLinksNull = true;
                    this.send = false;
                }else{
                    this.addLinksNull = false;
                }

                if(!this.clientsValue){
                    this.clientsNull = true;
                    this.send = false;
                }else{
                    this.clientsNull = false;
                }


                if(this.send){

                    const links = [];
                    const tematicas = [];

                    this.links.forEach(e=>{
                        links.push(e);
                    })

                    this.treeValue.forEach(e=>{
                        tematicas.push(e);
                    })

                    let stringLinks = String(links);
                    let stringTematicas = String(tematicas);

                    const arrayName = {
                        title: this.title,
                        data: this.data,
                        links: stringLinks,
                        id_themes: stringTematicas,
                        id_client: this.clientsValue,
                        miro: this.miroValue
                    }

                    this.registerProblem(arrayName);

                }

            },

            async registerProblem(data){

                this.sending = true;

                const saveProblems = await saveProblem(data)
                    if(saveProblems.status == 201){
                        return saveProblems.json().then( async (data) =>{
                            this.notify = !this.notify ? true : false;
                            const idUser = data.data.id_user;

                            const createdIdUser =  {
                                id: idUser,
                            }

                            const newNotify = await notifyUsers(createdIdUser);

                                if(newNotify.status == 202){
                                    this.finalizado = !this.finalizado ? true : false;
                                    this.links = [""];
                                    this.treeValue = [];
                                    this.title = null;
                                    this.data = null;
                                    this.clientsValue = null;
                                    this.miroValue = null;
                                    return;
                                }

                                this.sending = false;
                                toast.error("La problemática se guardo con éxito pero ocurrió un problema al notificar a los administradores.", {
                                    position: "bottom-right",
                                    timeout: 5000,
                                    pauseOnHover: true,
                                    hideProgressBar: true,
                                    icon: false,
                                    closeButton: false,
                                });


                        })
                    }else if(saveProblems.status == 422){
                        this.sending = false;
                        return saveProblems.json().then(data=>{
                            const errors = data.error.error;

                            if(errors.title){
                                toast.error("El título de esta problemática ya se encuentra en uso.", {
                                    position: "bottom-right",
                                    timeout: 5000,
                                    pauseOnHover: true,
                                    hideProgressBar: true,
                                    icon: false,
                                    closeButton: false,
                                });
                            }else{
                                toast.error("Ocurrió un problema al guardar el problemática.", {
                                    position: "bottom-right",
                                    timeout: 5000,
                                    pauseOnHover: true,
                                    hideProgressBar: true,
                                    icon: false,
                                    closeButton: false,
                                });
                            }

                        })
                    }else if(saveProblems.status == 400){
                        this.sending = false;
                        return saveProblems.json().then(()=>{
                            toast.error("Los enlaces no son validos.", {
                                position: "bottom-right",
                                timeout: 5000,
                                pauseOnHover: true,
                                hideProgressBar: true,
                                icon: false,
                                closeButton: false,
                            });
                        })
                    }else{
                        this.sending = false;
                        toast.error("Ocurrió al guardar el problemática.", {
                            position: "bottom-right",
                            timeout: 5000,
                            pauseOnHover: true,
                            hideProgressBar: true,
                            icon: false,
                            closeButton: false,
                        });
                    }
            },

            closeModal(){
                this.sending = false;
            }

        },
        mounted() {
            this.getClients();
            this.getThemes();

        }

    }

</script>
