<template>
  <div>
    <MenuComponent />
    <section class="container private">
      <HeaderPrivateView />
      <section class="admin-modificacion">
        <h1>Departamentos</h1>
        <section class="new">
          <input type="text" v-model="departament" placeholder="Nuevo" />
          <button @click="saveDepartament" :disabled="save">
            <i v-if="!save" class="bi bi-plus"></i>
            <n-space v-if="save">
              <n-spin size="small" stroke="$bs-success" />
            </n-space>
          </button>
        </section>
      </section>
      <section class="container-items">
        <section class="all-items" v-if="load">
              <section class="items-departaments user-modify" v-for="item in items" :key="item.id">
                <span @dblclick="editElement" @blur="resetElement">
                  {{ item.label }}
                </span>
                <button
                  class="save"
                  @click="updateDepartamento(item.value, $event.currentTarget)"
                  :disabled="true"
                >
                  <i class="bi bi-check2"></i>
                </button>
                <button class="delete" @click="deleteDepartamento(item.value, $event.currentTarget)">
                  <i class="bi bi-trash3"></i>
                </button>
              </section>
        </section>
        <section class="skelenton-container" v-else>
          <SkeletonModal/>
        </section>
      </section>
    </section>
  </div>
</template>

<script>
import MenuComponent from "@/components/private/MenuComponent.vue";
import HeaderPrivateView from "@/components/private/admin/HeaderPrivateView.vue";
import {
  saveDepartaments,
  teams,
  deleteDepartaments,
  updateDepartaments
} from "@/helpers/fetchs/teams";
import SkeletonModal from "@/components/extras/SkeletonModal.vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: {
    MenuComponent,
    HeaderPrivateView,
    SkeletonModal
  },
  data() {
    return {
      departament: "",
      save: false,
      items: null,
      contentEdit: "",
      update: false,
      load: false,
    };
  },
  methods: {
    async saveDepartament() {
      const input = document.querySelector(".new");
      const inputData = document.querySelector("input");
      if (!this.departament) {
        input.classList.add("null");
        return;
      }

      input.classList.remove("null");
      inputData.disabled = true;
      this.save = true;

      let departament = {
        name: this.departament,
      };

      const departamentNew = await saveDepartaments(departament);

        if (departamentNew.status == 422) {
          inputData.disabled = false;
          this.save = false;
          this.departament = null;
          toast.error(`El departamento ya existe.`, {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
        } else if (departamentNew.status == 201) {
          toast.success(`El departamento se guardo con éxito.`, {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
          inputData.disabled = false;
          this.save = false;
          this.departament = null;
          this.getDeparaments();
        } else {
          toast.error(
            `Tenemos problemas para guardar este departamento, por favor inténtelo más tarde.`,
            {
              position: "bottom-right",
              timeout: 5000,
              pauseOnHover: true,
              hideProgressBar: true,
              icon: false,
              closeButton: false,
            }
          );
          inputData.disabled = false;
          this.save = false;
        }
    },

    async getDeparaments() {

      const allTeams = await teams();
      this.items = allTeams;
      this.load = true;
      
    },

    editElement(event) {
      const element = event.target;
      const containerPadre = element.parentNode;
      const botonSave = containerPadre.querySelector(".save");
      const text = element.innerHTML;
      this.contentEdit = text;

      element.contentEditable = true;
      element.classList.add("edit");
      botonSave.disabled = false;

      element.addEventListener("input", () => {
        const contenidoActual = element.innerHTML;

        if (contenidoActual.length && contenidoActual != text) {
          botonSave.disabled = false;
        } else {
          botonSave.disabled = true;
        }
      });
    },

    async deleteDepartamento(id, event) {

      event.disabled = true;

      let departament = {
        id: id,
      };

      const deleteItem = await deleteDepartaments(departament)

        if (deleteItem.status == 202) {
          this.getDeparaments();
          toast.success(`El departamento se elimino con éxito.`, {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
        } else {
          event.disabled = false;
          toast.error(
            `Tenemos problemas para guardar este departamento, por favor inténtelo más tarde.`,
            {
              position: "bottom-right",
              timeout: 5000,
              pauseOnHover: true,
              hideProgressBar: true,
              icon: false,
              closeButton: false,
            }
          );
        }

    },

    async updateDepartamento(id, event) {
      const textSave = event.parentNode.querySelector("span").textContent;

      event.disabled = true;
      

      let departament = {
        id: id,
        name: textSave,
      };

      const update = await updateDepartaments(departament);

        if (update.status == 202) {
          toast.success(`El departamento se actualizo con éxito.`, {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
          this.getDeparaments();
          this.update = true;
        } else {
          toast.error(
            `Tenemos problemas para actualizar este departamento, por favor inténtelo más tarde.`,
            {
              position: "bottom-right",
              timeout: 5000,
              pauseOnHover: true,
              hideProgressBar: true,
              icon: false,
              closeButton: false,
            }
          );
        }
    },

    resetElement(event) {
      this.getDeparaments();

      setTimeout(() => {
        if (!this.update) {
          const element = event.target;
          const containerPadre = element.parentNode;
          const botonSave = containerPadre.querySelector(".save");
          element.contentEditable = false;
          element.innerHTML = this.contentEdit;
          element.classList.remove("edit");
          botonSave.disabled = true;
        } else {
          const element = event.target;
          const containerPadre = element.parentNode;
          const botonSave = containerPadre.querySelector(".save");
          element.contentEditable = false;
          element.classList.remove("edit");
          botonSave.disabled = true;
        }
      }, 300);
    },
  },
  mounted() {
    this.getDeparaments();
  },
  created() {
    this.getDeparaments();
  },
};
</script>