import router from "@/router/index";
import store from '@/store/index';

const domain = "https://g10-admin.garnierbbdo.com/api/";

import { token } from '@/helpers/getTokeUser';

function close() {
  localStorage.clear();
  store.dispatch('resetUser');
  router.push({ name: "login" });
}

const saveProblem = async (data) => {

  return await fetch(`${domain}problems`, {
    method: "POST",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

const notifyUsers = async (data) => {

  return await fetch(`${domain}problems-notify`, {
    method: "POST",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

const problemsNotApproved = async () => {

  return await fetch(`${domain}problems-sin-aprobar`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 200) {
      return res.json().then((data) => {
        return data.data.items;
      });
    } else if (res.status != 404) {
      close();
    } else if (res.status == 404) {
      return null;
    }
  });
};

const updateProblem = async (data, slug) => {

  return await fetch(`${domain}problems-edit/${slug}`, {
    method: "PUT",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

const problemsIndividualNotApproved = async (slug) => {

  return await fetch(`${domain}problems-sin-aprobar/${slug}`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 200) {
      return res.json().then((data) => {
        return data.data.item;
      });
    } else if (res.status != 404) {
        close();
    } else {
      return null;
    }
  });
};

const updateProblemTheme = async (data, slug) => {

  return await fetch(`${domain}problems-edit-theme/${slug}`, {
    method: "PUT",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

const problemsRemove = async () => {

  return await fetch(`${domain}problems-papelera`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 200) {
      return res.json().then((data) => {
        return data.data.items;
      });
    } else if (res.status != 404) {
      close();
    } else if (res.status == 404) {
      return null;
    }
  });
};

export {
  saveProblem,
  notifyUsers,
  problemsNotApproved,
  updateProblem,
  problemsIndividualNotApproved,
  updateProblemTheme,
  problemsRemove,
};
