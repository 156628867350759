<template>
    <div>
        <HeaderPrivateView/>
        <section class="home private">
            <router-link class="items" :to="{name: 'problemas-pendientes'}">Problemáticas pendientes <i class="bi bi-plus"></i></router-link>
            <router-link class="items" :to="{name: 'clientes'}">Clientes <i class="bi bi-plus"></i></router-link>
            <router-link class="items" :to="{name: 'departamentos'}">Departamentos <i class="bi bi-plus"></i></router-link>
            <router-link class="items" :to="{name: 'tematicas'}">Temáticas <i class="bi bi-plus"></i></router-link>
        </section>
    </div>
</template>

<script>

    import HeaderPrivateView from '@/components/private/admin/HeaderPrivateView.vue';

    export default{

        components: {
            HeaderPrivateView
        }

    }

</script>