<template>
  <div class="tematicas">
    <MenuComponent />
    <section class="container private">
      <HeaderPrivateView />
      <section class="admin-modificacion">
          <section class="title">
            <h1>Temáticas</h1>  
            <ButtonTrashComponent nameRoute="tematicasTrash" />
          </section>
        <section class="new" :class="error ? 'null' : ''">
          <input
            type="text"
            :disabled="save"
            v-model="tematica"
            placeholder="Nueva"
          />
          <section class="botonItems">
            <n-color-picker
              :disabled="save"
              :swatches="[
                '#FFFFFF',
                '#18A058',
                '#2080F0',
                '#F0A020',
                'rgba(208, 48, 80, 1)',
              ]"
              v-model:value="color"
              :show-preview="true"
            />
            <button @click="saveTematica" :disabled="save">
              <i v-if="!save" class="bi bi-plus"></i>
              <n-space v-if="save">
                <n-spin size="small" stroke="$bs-success" />
              </n-space>
            </button>
          </section>
        </section>
      </section>
      <section class="container-items">
        <section class="all-items" v-if="load">
              <section class="items-departaments user-modify" v-for="item in items" :key="item.id">
                <section
                  class="itemColor"
                  :style="{ backgroundColor: item.color }"
                ></section>
                <span @dblclick="editElement" @blur="resetElement">
                  {{ item.name }}
                </span>
                <button
                  class="save"
                  @click="updateTheme(item.id, $event.currentTarget)"
                  :disabled="true"
                >
                  <i class="bi bi-check2"></i>
                </button>
                <button class="delete" @click="deleteThemes(item.id, $event.currentTarget)">
                  <i class="bi bi-trash3"></i>
                </button>
              </section>
        </section>
        <section class="skelenton-container" v-else>
          <SkeletonModal/>
        </section>
      </section>
    </section>
  </div>
</template>

<script>
import HeaderPrivateView from "@/components/private/admin/HeaderPrivateView.vue";
import MenuComponent from "@/components/private/MenuComponent.vue";
import {
  saveTheme,
  teams,
  updateTheme,
  deleteThemes
} from "@/helpers/fetchs/themes";
import SkeletonModal from "@/components/extras/SkeletonModal.vue";
import ButtonTrashComponent from '@/components/buttons/trash/ButtonTrashComponent.vue';
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: {
    HeaderPrivateView,
    MenuComponent,
    SkeletonModal,
    ButtonTrashComponent
  },
  data() {
    return {
      tematica: "",
      save: false,
      color: "#00CC7AFF",
      error: false,
      items: null,
      contentEdit: "",
      update: false,
      load: false,
      updated: false,
    };
  },
  methods: {
    async saveTematica() {
      if (!this.tematica) {
        this.error = true;
        return;
      }

      this.error = false;
      this.save = true;

      const data = {
        name: this.tematica,
        color: this.color,
      };

      const saveItems = await saveTheme(data);

      this.save = false;

        if (saveItems.status == 201) {
          this.tematica = null;
          this.getThemes();
          this.color = "#00CC7AFF";
          toast.success(`La temáticas se guardo con éxito.`, {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
        } else if (saveItems.status == 422) {
          this.tematica = null;
          return saveItems.json().then((data) => {
            const errors = data.error.error;
            if (errors.color && errors.name) {
              this.tematica = null;
              toast.error(
                `El nombre y el color de la temática ya estan registrados.`,
                {
                  position: "bottom-right",
                  timeout: 5000,
                  pauseOnHover: true,
                  hideProgressBar: true,
                  icon: false,
                  closeButton: false,
                }
              );
            } else if (errors.color) {
              toast.error(`El color de la temáticas ya esta registrado.`, {
                position: "bottom-right",
                timeout: 5000,
                pauseOnHover: true,
                hideProgressBar: true,
                icon: false,
                closeButton: false,
              });
            } else if (errors.name) {
              this.tematica = null;
              toast.error(`El nombre de la temáticas ya esta registrado.`, {
                position: "bottom-right",
                timeout: 5000,
                pauseOnHover: true,
                hideProgressBar: true,
                icon: false,
                closeButton: false,
              });
            }
          });
        } else {
          toast.error(
            `Tenemos problemas para guardar la temáticas, por favor inténtelo más tarde.`,
            {
              position: "bottom-right",
              timeout: 5000,
              pauseOnHover: true,
              hideProgressBar: true,
              icon: false,
              closeButton: false,
            }
          );
        }
    },
    async getThemes() {

      const themesItems = await teams();

      this.load = true;

      this.items = themesItems;

    },

    async updateTheme(id, event) {

      event.disabled = true;

      const textSave = event.parentNode.querySelector("span").textContent;

      let themes = {
        id: id,
        name: textSave,
      };

      const updateItem = await updateTheme(themes);

        if (updateItem.status == 202) {
          toast.success(`La temática se actualizo con éxito.`, {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
          this.getThemes();
          this.update = true;
        } else if (updateItem.status == 422) {
          return updateItem.json().then(data=>{

            if(data.error.error){
              if(data.error.error.name){
                toast.error(
                  `El nombre de la temática ya está registrado.`,
                  {
                    position: "bottom-right",
                    timeout: 5000,
                    pauseOnHover: true,
                    hideProgressBar: true,
                    icon: false,
                    closeButton: false,
                  }
                );
              }
            }

          })
        }else{
          toast.error(
            `Tenemos problemas para actualizar la temáticas, por favor inténtelo más tarde.`,
            {
              position: "bottom-right",
              timeout: 5000,
              pauseOnHover: true,
              hideProgressBar: true,
              icon: false,
              closeButton: false,
            }
          );
        }

    },

    editElement(event) {
      const element = event.target;
      const containerPadre = element.parentNode;
      const botonSave = containerPadre.querySelector(".save");
      const text = element.innerHTML;
      this.contentEdit = text;

      element.contentEditable = true;
      element.classList.add("edit");
      botonSave.disabled = false;

      element.addEventListener("input", () => {
        const contenidoActual = element.innerHTML;

        if (contenidoActual.length && contenidoActual != text) {
          botonSave.disabled = false;
        } else {
          botonSave.disabled = true;
        }
      });
    },

    resetElement(event) {
      this.getThemes();

      setTimeout(() => {
        if (!this.update) {
          const element = event.target;
          const containerPadre = element.parentNode;
          const botonSave = containerPadre.querySelector(".save");
          element.contentEditable = false;
          element.innerHTML = this.contentEdit;
          element.classList.remove("edit");
          botonSave.disabled = true;
        } else {
          const element = event.target;
          const containerPadre = element.parentNode;
          const botonSave = containerPadre.querySelector(".save");
          element.contentEditable = false;
          element.classList.remove("edit");
          botonSave.disabled = true;
        }
      }, 300);
    },

    async deleteThemes(id, event) {

      const data = {
        id: id,
      };

      event.disabled = true;

      const deleteItem = await deleteThemes(data);

        if (deleteItem.status == 202) {
          toast.success("La temática se elimino con éxito.", {
            position: "bottom-right",
            timeout: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
        } else {

          event.disabled = false;

          toast.error(
            "Tenemos problemas para eliminar la temática, por favor inténtelo más tarde.",
            {
              position: "bottom-right",
              timeout: 5000,
              pauseOnHover: true,
              hideProgressBar: true,
              icon: false,
              closeButton: false,
            }
          );
        }

        this.getThemes();

    },
  },
  created() {
    this.getThemes();
  },
};
</script>

