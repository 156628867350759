<template>
    <div class="login center h-v-100">
      <HeaderAuth />
      <section class="container form">
        <form v-if="tokenValidate">
          <section class="individual">
            <label for="password" class="relative_inputs helvicaFont">
              <section class="relative_inputs helvicaFont">
                <span class="absolute_input">Contraseña</span>
                <input
                  type="password"
                  v-model="value.password"
                  id="password"
                  v-on:focus="focus_input($event)"
                  v-on:blur="blur_input($event)"
                  autocomplete="new-password"
                />
              </section>
              <div class="error password" />
            </label>
          </section>
          <section class="individual">
            <label for="repeat" class="relative_inputs helvicaFont">
              <section class="relative_inputs helvicaFont">
                <span class="absolute_input">Confirmar Contraseña</span>
                <input
                  type="password"
                  name="repeat"
                  v-model="value.repeat"
                  id="repeat"
                  v-on:focus="focus_input($event)"
                  v-on:blur="blur_input($event)"
                  autocomplete="new-password"
                />
                <button
                  type="button"
                  :disabled="botton.disabled"
                  class="send_move"
                  v-on:mouseover="focus_button()"
                  v-on:mouseout="blur_button()"
                  v-on:click="update()"
                >
                  <svg width="22" viewBox="0 0 45 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24.9 40.17L43.44 20.59L24.9 1" stroke="white" stroke-miterlimit="10"/>
                      <path d="M43.45 20.59H0" stroke="white" stroke-miterlimit="10"/>
                  </svg>
                </button>
              </section>
              <div class="error" />
            </label>
          </section>
        </form>
        <section v-else class="container activeAccount verify-active active helvicaFont">
              <h4 class="helvicaFont"> <span v-html="contentLoading"></span> ({{loading}}%)</h4>
              <div class="loading">
                  <div class="progress" :style="{'width': `${loading}%`, 'background-color': color}"></div>
              </div>
              <router-link v-if="getLogin" :to="{name: 'login'}" class="volverAlinicio">Iniciar sesión</router-link>
        </section>
      </section>
      <FooterAuth />
    </div>
  </template>
  
  <script>
  import validation from "@/helpers/validation";
  import HeaderAuth from "@/components/public/HeaderAuth.vue";
  import FooterAuth from "@/components/public/FooterAuth.vue";
  
  // Fetch api
  import {
    newPassword,
    ValidateUnbloquedUser
  } from '@/helpers/fetchs/user';
  
  
  export default {
    components: {
      HeaderAuth,
      FooterAuth,
    },
    data() {
      return {
          validation_all: {
              inputs: 0
          },
          value: {
          password: "",
          repeat: ""
          },
          botton: {
            register: "",
            disabled: false,
          },
          tokenValidate: true,
          loading: 0,
          color: '#FFB600',
          contentLoading: 'Verificando usuario...',
          getLogin: false,
      };
    },
    methods: {
      focus_input(element) {
        const element_child = element.target.parentNode.querySelector("span");
        element_child.classList.add("active");
      },
  
      blur_input(element) {
        const element_child = element.target.parentNode.querySelector("span");
        const element_input = element.target;
        const type = "individual";
  
        element_input.value
          ? element_child.classList.add("active")
          : element_child.classList.remove("active");
  
        validation(element_input, type);
      },
  
      focus_button() {
        this.botton.registro = "Registrarse";
      },
  
      blur_button() {
        this.botton.registro = "";
      },
  
      async update() {
          this.validation();
          if(this.validation_all.inputs == 2){
  
              const token = this.$route.params.token;
              const form = new FormData();
              form.append('password', this.value.password);
  
            const element = document.querySelectorAll('input');
            element.forEach(e=>{
              e.disabled = true;
            })
  
            this.botton.disabled = true;

              await newPassword(form, token)
              .then(res=>{  
                if(res.status == 404){
                  this.tokenValidate = false;
                }else if(res.status == 201){
                  this.tokenValidate = false;
                  this.getLogin = true;
                  this.loading = 99;
                  this.color = '$bs-success';
                  this.contentLoading = 'Su contraseña se ha restablecido con éxito';
                }else{
                    this.tokenValidate = false;
                }

              });
          }
      },
  
      validation() {
        this.validation_all.inputs = 0;
        const elements_child = document.querySelectorAll("input");
        const type = "individual";
        elements_child.forEach((e) => {
  
          if(e.value){
              e.classList.remove('error-input')
          }
  
          if (!validation(e, type)) {
            this.validation_all.inputs += 1;
          }
  
        });
      },
  
      async validateUrl(){
  
        const loading_pause = setInterval(()=>{
            this.loading += 1;
            if(this.loading >= 100){
                clearInterval(loading_pause);
            }
        }, 200);
  
        const token = this.$route.params.token;
        await ValidateUnbloquedUser(token)
        .then(res=>{
          if(res.status == 404){
            this.tokenValidate = false;
            this.loading = 99;
            this.color = '#FF0624'
            this.contentLoading = 'Esta url ya ha expirado <i class="bi bi-emoji-frown"></i>'
          }else if(res.status == 201){
            setTimeout(()=>{
              this.getLogin = true;
              this.loading = 99;
              this.color = '$bs-success';
              this.contentLoading = 'Su cuenta ha sido desbloquead con éxito';
              
            }, 100)
          }else{
            this.tokenValidate = true;
          }
  
        })
  
  
      }
  
    },
    mounted(){
      this.validateUrl();
    },
    created(){
      this.tokenValidate = false;
    }
  };
  </script>
  