import router from "@/router/index";
import store from '@/store/index';

const domain = "https://g10-admin.garnierbbdo.com/api/";

import { token } from '@/helpers/getTokeUser';

function close() {
  localStorage.clear();
  store.dispatch('resetUser');
  router.push({ name: "login" });
}

const getClientsPublic = async () => {


  return await fetch(`${domain}client-public`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 404) {
      return null;
    } else if (res.status == 200) {
      return res.json().then((data) => {
        return data.data.items;
      });
    } else {
      close();
    }
  });
};

const getClients = async () => {

  return await fetch(`${domain}client`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 404) {
      return null;
    } else if (res.status == 200) {
      return res.json().then((data) => {
        return data.data.items;
      });
    } else {
      close();
    }
  });
};

const saveClients = async (data) => {

  return await fetch(`${domain}client`, {
    method: "POST",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

const deleteClients = async (data) => {

  return await fetch(`${domain}client-admin`, {
    method: "PUT",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

const updateClients = async (data) => {

  return await fetch(`${domain}client`, {
    method: "PUT",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

const getClientsTrash = async () => {

  return await fetch(`${domain}client-admin`, {
    method: "GET",
    headers: await token(),
  }).then((res) => {
    if (res.status == 404) {
      return null;
    } else if (res.status == 200) {
      return res.json().then((data) => {
        return data.data.items;
      });
    } else {
      close();
    }
  });
};

const RestaurarClients = async (data) => {

  return await fetch(`${domain}client-active`, {
    method: "PUT",
    headers: await token(),
    body: JSON.stringify(data),
  }).then((res) => {
    return res;
  });
};

export {
  getClients,
  saveClients,
  deleteClients,
  updateClients,
  getClientsTrash,
  RestaurarClients,
  getClientsPublic
};
